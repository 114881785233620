export const ALIGNMENT = {
  monthly: {
    div: 1,
    off: 0,
    human: "10. eines Monats",
    min: 27,
    name: "monatlich",
  },
  quarterly: {
    div: 3,
    off: 0,
    human: "10. Januar, 10. April, 10. Juli, 10. Oktober",
    min: 83,
    name: "vierteljährlich",
  },
  halfyearly: {
    div: 6,
    off: 1,
    human: "10. Februar und 10. August",
    min: 104,
    name: "halbjährlich",
  },
  yearly: { div: 12, off: 2, human: "10. März", min: 364, name: "jährlich" },
}

export function nextValid (inputDate, alignment) {
  const floorDate = inputDate
  let retval = floorDate

  while (true) {
    if (retval.date() >= 10) {
      retval = retval.clone().add({ months: 1 })
    }
    const inc =
      ((alignment.div - (retval.month() % alignment.div)) % alignment.div) +
      alignment.off
    retval = retval.clone().date(10).add({ months: inc })

    if (retval.diff(floorDate, "days") >= alignment.min) {
      break
    }
  }
  return retval
}

export function availableOptions (start, end) {
  const options = Object.entries(ALIGNMENT).filter(([codeA, objA]) => {
    if (!end) {
      return true
    }
    const x = nextValid(nextValid(start, objA), objA)
    return x.isSameOrBefore(end)
  })
  options.sort(([codeA, objA], [codeB, objB]) => objA.div - objB.div)
  return options.map(([code, obj]) => ({ text: obj.name, value: code }))
}
