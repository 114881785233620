import UnionReplacer from "union-replacer"

const parishReplacer = new UnionReplacer([
  [/Evangelischer?/, "Ev."],
  [/Evangelisch-reformierter?/, "Ev.-ref."],
  [/Evangelisch-Lutherischer?/, "Ev.-Luth."],
  [/Kirchenkreis/, "KK"],
  [/Auferstehungskirchengemeinde/, "Auferstehungs-KG"],
  [/Kirchengemeinde/, "KG"],
])

export function shortenParish (value) {
  return parishReplacer.replace(value)
}

const PREPOSITION_RE = /^([Ff]ür\s(?:d..\s)?)(.*)$/s

export function splitPurpose (value) {
  if (!value) {
    return {
      preposition: "Für die",
      purpose: "---",
    }
  }
  const matches = PREPOSITION_RE.exec(value)
  if (!matches) {
    return {
      preposition: "",
      purpose: value.trim(),
    }
  }
  return {
    preposition: matches[1].trim(),
    purpose: matches[2].trim(),
  }
}

export function extractDisplayPurpose (collectionPlan, deviceType = "john") {
  const display = collectionPlan?.display
  if (!display) {
    return ""
  }

  return display[deviceType] ?? display?.default ?? ""
}

const matchAdjective = /^(?:Evangelische?\S*|Lippisch\S*|Zoologisch\S*)/i

const matchDer = /^(?:[a-z]*kreis|[a-z]*bereich|tierpark|garten)/i
const matchDie =
  /^(?:(?:\S+\s*)?gemeinde|stiftung|abtei|EKD|(?:\S+\s*)?kirche\b|.*?klasse)/i
const matchDas = /^(?:(?:\S+\s*)?werk|(?:\S+\s*)?zentrum|projekt)/i
const matchEV = /e\.\S*V\.$/i

export function getGermanGenus (text) {
  const strippedText = text.trim().replace(matchAdjective, "").trim()
  if (matchDer.test(strippedText)) {
    return "der"
  }
  if (matchDie.test(strippedText)) {
    return "die"
  }
  if (matchDas.test(strippedText)) {
    return "das"
  }
  if (matchEV.test(strippedText)) {
    return "der"
  }
  return null
}

export function mapGermanGenusAkkusativ (artikel) {
  if (artikel === "der") {
    return "den"
  }
  if (artikel === "die") {
    return "die"
  }
  if (artikel === "das") {
    return "das"
  }
  return "das"
}

export function transformGermanAkkusativ (text) {
  const genus = getGermanGenus(text)
  const match = matchAdjective.exec(text.trim())

  let retval

  if (match) {
    let adjective = match[0].trim()
    const otherText = text.slice(match[0].length).trim()

    if (genus === "der") {
      // den
      adjective = adjective.replace(/er?$/, "en")
    }

    if (genus === "das") {
      // das
      adjective = adjective.replace(/es$/, "e")
    }

    // console.log({ genus, adjective, otherText })

    retval = mapGermanGenusAkkusativ(genus) + " " + adjective + " " + otherText
  } else {
    retval = mapGermanGenusAkkusativ(genus) + " " + text
  }

  return retval
}
