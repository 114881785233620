<template>
  <div>
    <div
      v-if="state && state !== STATE_INIT"
      id="paypal-fragment-container"
      ref="paypal-fragment-container"
    >
      <v-expand-transition>
        <div v-if="loading" style="width: 3em" class="ma-auto mb-4">
          <v-progress-circular
            color="primary"
            :size="70"
            :width="5"
            indeterminate
          />
        </div>
      </v-expand-transition>
    </div>
    <template v-if="state === STATE_INIT">
      <slot name="initiatingpre" />
      <slot name="initiatingpost" />
    </template>
    <slot name="actions" />
    <slot name="footer" :footerInfo="additionalFooterInformation" />
  </div>
</template>

<script>
import {
  STATE_DONE,
  STATE_EXEC,
  STATE_HANDLE,
  STATE_INIT,
} from "../store/payment/state-types"
import { mapState } from "vuex"
import {
  SET_MEMO_TEXT,
  UPDATE_INTEGRATION_DATA,
} from "../store/payment/mutation-types"
import { getDonePath } from "@/router"
import axios from "axios"
import config from "@/config"

export default {
  name: "PaymentFragmentPaypal",
  props: {
    state: {
      type: String,
      required: false,
      default: null,
    },
    appKind: {
      validator: (x) => ["spende.app", "kollekte.app"].includes(x),
      default: "kollekte.app",
    },
    selectedMethod: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      loading: true,
      loaded: false,
      STATE_INIT,
    }
  },
  computed: {
    ...mapState("payment", ["integrationData", "memoText"]),
    ...mapState("payment", {
      paymentInput: "input",
    }),
    paypalParams () {
      let clientId = null
      let sandbox = null
      this.paymentInput.paymentInformation.forEach((pi) => {
        pi.integrations.forEach((i) => {
          if (i?.integration === "paypal") {
            clientId = i?.client_id ?? clientId
            sandbox = sandbox || i?.environment !== "production"
          }
        })
      })
      if (sandbox === null) {
        sandbox = true
      }

      return { clientId, environment: sandbox ? "sandbox" : "production" }
    },
    additionalFooterInformation () {
      if (this.memoText) {
        return `Dort erscheint „${this.memoText}“ als Verwendungszweck.`
      }

      const merchantMemo = this.paymentInput?.merchantMemo || ""

      if (merchantMemo) {
        return `Dort erscheint „${merchantMemo}“ als Verwendungszweck.`
      }

      return 'Dort erscheint das Wort „Spende" und der Name der Organisation als Verwendungszweck.'
    },
  },
  watch: {
    selectedMethod: {
      immediate: true,
      async handler (newValue) {
        if (newValue?.integration === "paypal") {
          this.$emit("request-additional-fields", new Set([]))
        }
      },
    },
    state: {
      immediate: true,
      async handler (newValue) {
        if (this.selectedMethod.integration !== "paypal") {
          return
        }
        if (newValue === STATE_HANDLE) {
          await this.openPaypal()
          this.$emit("goto", STATE_EXEC)
        } else if (newValue === STATE_EXEC) {
          // The paypal button is in an iframe, we cannot click it.
          /*
          document.querySelectorAll('#paypal-fragment-container .paypal-button').forEach(
            element => { console.log({ element }); element.click() }
          )
          */
        }
        if ([STATE_DONE, STATE_INIT, null].includes(newValue) && this.loaded) {
          this.removePaypal()
        }
        if ([STATE_INIT, null].includes(newValue)) {
          this.loading = true
        }
      },
    },
  },
  beforeDestroy () {
    if (this.loaded) {
      this.removePaypal()
    }
  },
  methods: {
    async openPaypal () {
      const paypalLoader = new Promise((resolve) => {
        if (!(window?.paypal ?? null)) {
          const paypalScript = document.createElement("script")
          paypalScript.setAttribute("data-fragment-uid", this._uid.toString())
          paypalScript.src = "https://www.paypalobjects.com/api/checkout.js"
          paypalScript.onload = function () {
            resolve()
          }
          document.head.appendChild(paypalScript)
        } else {
          resolve()
        }
      })

      const [paypalPaymentId] = await Promise.all([
        this.startPaymentOnBackend(),
        paypalLoader,
      ])

      const vm = this
      await window.paypal.Button.render(
        {
          env: vm.paypalParams.environment,
          commit: true,
          locale: "de_DE",
          style: {
            size: "medium",
            label: "paypal",
            tagline: false,
          },
          onRender: () => {
            vm.loading = false
          },
          payment: () => {
            return paypalPaymentId
          },
          onAuthorize: async (data, actions) => {
            const authorizationData = {
              paypalPaymentId: data.paymentID,
              paypalPayerId: data.payerID,
            }
            await vm.confirmPaymentOnBackend(authorizationData)
          },
        },
        "#paypal-fragment-container"
      )
      this.loaded = true
    },
    removePaypal () {
      if (this.$refs["paypal-fragment-container"]) {
        for (const child of Array.from(
          this.$refs["paypal-fragment-container"].childNodes
        )) {
          child.remove()
        }
      }
      for (const child of Array.from(document.head.childNodes)) {
        if (
          child.tagName === "SCRIPT" &&
          child.getAttribute("src") ===
            "https://www.paypalobjects.com/api/checkout.js" &&
          child?.dataset?.fragmentUid === this._uid.toString()
        ) {
          child.remove()
        }
      }
      this.loaded = false
    },
    async startPaymentOnBackend () {
      const path = getDonePath({
        organizationId: this.paymentInput.parishId,
      }).href
      const successUrl = window.location.origin + path
      const response = await axios.post(
        `${config.backend.rest}app/online_payment/start_payment/?app=${this.appKind}`,
        {
          collection_id: this.paymentInput.collection.id,
          collection: this.paymentInput.collection.id,
          amount: this.paymentInput.amount,
          ...this.selectedMethod,
          return_url_base: successUrl,
          organization: this.paymentInput.parishId,
          person_data: this.$store.state.payment.personData,
        }
      )
      const data = response.data
      const paypalPaymentId = data.paypal_payment_id || null
      const paypalOrderId = data?.paypal_order_id ?? null
      await Promise.allSettled([
        this.$store.commit("payment/" + UPDATE_INTEGRATION_DATA, {
          onlinePaymentId: data.id,
        }),
        this.$store.commit("payment/" + SET_MEMO_TEXT, {
          memoText: data?.memo_text || null,
        }),
      ])

      return (paypalPaymentId ?? null) || (paypalOrderId ?? null)
    },
    async confirmPaymentOnBackend ({ paypalPayerId }) {
      try {
        const response = await axios.post(
          `${config.backend.rest}app/online_payment/update_payment/?app=${this.appKind}`,
          {
            online_payment: this.integrationData.onlinePaymentId,
            paypal_payer_id: paypalPayerId,
          }
        )
        const data = response.data
        await this.$store.commit("payment/" + SET_MEMO_TEXT, {
          memoText: data?.memo_text || null,
        })
        this.$emit("goto", "done", {
          successful: ["success", "pending", "hold"].includes(
            data.final_result_type
          ),
          data,
        })
      } catch (error) {
        this.$emit("goto", "done", {
          successful: false,
          message: `Technischer Fehler in der Datenübermittlung: ${error}`,
        })
      }
    },
  },
}
</script>

<style scoped lang="stylus"></style>
