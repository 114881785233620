var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main",attrs:{"id":"kb-main"}},[_c('div',{staticClass:"logo-layer"},[_c('div',{staticClass:"top-row"},[_c('div',{staticClass:"top-left"}),_c('div',{staticClass:"top-right"},[_c('DefaultCorner',{attrs:{"id":"branding_logo"}})],1)]),_vm._m(0)]),_c('div',{staticClass:"controls"},[_c('div',{staticClass:"display-row control-row"},[_c('div',{staticClass:"display-container"},[_c('div',{staticClass:"arrowLeft"},[(
              _vm.ready &&
              _vm.collections.length > 1 &&
              !this.$store.state.singleCollection
            )?_c('div',{staticClass:"arrowDiv clickable",on:{"click":_vm.moveToPreviousCollection}}):_vm._e()]),_c('div',{staticClass:"display"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"min-width":"2.1em"}}),_c('div',{staticClass:"amount clickable",on:{"click":_vm.selectCustomValue}},[(_vm.value < 200)?_c('span',[_vm._v(_vm._s(_vm.value))]):_c('div',{staticClass:"custom-amount"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.customValue),expression:"customValue",modifiers:{"number":true}}],ref:"customAmount",staticClass:"customAmount",attrs:{"type":"number","inputmode":"numeric","pattern":"[0-9]*","min":"1","max":_vm.inputMax},domProps:{"value":(_vm.customValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.customValue=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v("  € ")]),(_vm.currentCollection)?_c('div',{staticStyle:{"width":"2.1em"}},[(
                  _vm.currentCollection.plan
                    ? _vm.currentCollection.plan.meta
                      ? _vm.currentCollection.plan.meta.description
                        ? _vm.currentCollection.plan.meta.description.text
                          ? true
                          : false
                        : false
                      : false
                    : false
                )?_c('div',{staticClass:"info-icon",on:{"click":function($event){return _vm.openDetailDescription(_vm.currentCollection)}}},[_c('span',{staticStyle:{"font-family":"Times New Roman","font-weight":"bold","font-size":"1.1em","line-height":"1.4em"}},[_vm._v("i")])]):_vm._e()]):_vm._e()]),_c('div',{class:{
              preposition: true,
              clickable: !this.$store.state.singleCollection && _vm.ready,
            },on:{"click":_vm.startSelectCollection}},[_vm._v(" "+_vm._s(_vm.collectionDisplay.preposition)+" ")]),_c('div',{class:{
              purpose: true,
              clickable: !this.$store.state.singleCollection && _vm.ready,
            },style:({
              height: (_vm.collectionDisplay.preposition === '' ? 76 : 58) + 'px',
            }),on:{"click":_vm.startSelectCollection}},[(_vm.ready)?_c('div',{style:([
                'display: block;',
                {
                  lineHeight:
                    (_vm.purposeFontSize === null ? 10 : _vm.purposeFontSize) +
                    2 +
                    'px',
                  fontSize:
                    (_vm.purposeFontSize === null ? 10 : _vm.purposeFontSize) + 'px',
                } ]),attrs:{"id":"purposeSpan"},domProps:{"innerHTML":_vm._s(_vm.fixNewlines(_vm.escapeHtml(_vm.collectionDisplay.purpose)))}}):_c('span',[_vm._v(_vm._s(_vm.collectionDisplay.purpose))])])]),_c('div',{staticClass:"arrowRight"},[(
              _vm.ready &&
              _vm.collections.length > 1 &&
              !this.$store.state.singleCollection
            )?_c('div',{staticClass:"arrowDiv clickable",on:{"click":_vm.moveToNextCollection}}):_vm._e()])]),(_vm.ready && !this.$store.state.singleCollection)?_c('div',{staticClass:"display-dots"},_vm._l((_vm.collections),function(collection,index){return _c('span',{key:collection.id,staticClass:"purposeDotSelection clickable",on:{"click":function($event){return _vm.selectCollection($event, index)}}},[(_vm.ready && _vm.collections.length > 1)?_c('span',[(index === _vm.collectionIndex)?_c('span',{staticClass:"purposeDots"},[_vm._v("⦿")]):_c('span',{staticClass:"purposeDots"},[_vm._v("○")])]):_vm._e()])}),0):_vm._e()]),_c('div',{staticClass:"calendar-row control-row"},[(
          _vm.currentCollection &&
          _vm.currentCollection.plan &&
          _vm.currentCollection.plan.date
        )?[_vm._v(" "+_vm._s(_vm.currentCollection.plan.date.name)+", "+_vm._s(_vm.formatDate(_vm.currentCollection.plan.date.day, "D. MMM YYYY"))+" ")]:_vm._e()],2),_c('div',{staticClass:"slider-row control-row"},[_c('div',{staticClass:"slider",attrs:{"id":"value-slider"}},[_c('vue-slider',{attrs:{"tooltip":_vm.value < 200 ? 'always' : 'none',"height":"0.7em","dot-size":"22","min":1,"max":Math.max(200, _vm.value)},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('div',{staticClass:"moreLabel clickable",on:{"click":function($event){return _vm.selectCustomValue($event)}}},[_vm._v(" mehr ")])]),(!_vm.hideParishSelector)?_c('div',{staticClass:"parish-row control-row"},[(_vm.ready)?_c('div',{class:{
          parish: true,
          clickable: !this.$store.state.singleCollection,
        },on:{"click":_vm.selectParish}},[_vm._v(" "+_vm._s(_vm.parishName)+" ")]):_c('div',{staticClass:"parish clickable",on:{"click":_vm.selectParish}},[_vm._v(" — Gemeinde wählen — ")])]):_vm._e(),_c('div',{staticClass:"paybutton-row control-row"},[_c('div',{staticClass:"paybutton-container clickable"},[_c('div',{staticClass:"start-payment",on:{"click":_vm.startPayment}},[_vm._v(" "+_vm._s(_vm.collectionTypeForDisplay)+" geben ")])])])]),_c('app-dialog',{ref:"collectionDialog",attrs:{"is-open":_vm.isCollectionDialogOpen},on:{"dialog-closed":function($event){_vm.isCollectionDialogOpen = false}}},[_c('branded-dialog-header',[_c('h4',[_vm._v(_vm._s(_vm.parishName))]),_vm._v(" Wählen Sie den Kollekten- oder Spendenzweck:"),_c('br'),_c('br')]),_c('div',{staticClass:"list-outline"},[_c('div',{staticClass:"collection-list-box"},_vm._l((_vm.collections),function(collection,index){return _c('div',{key:collection.id,class:{
            collectionListItem: true,
            active: index === _vm.collectionIndex,
            clickable: true,
          },on:{"click":function($event){return _vm.selectCollection($event, index)}}},[_c('div',{staticClass:"collection-title"},[(
                collection.plan.type !== null &&
                collection.plan.type === 'donation'
              )?_c('HandOutline',{staticClass:"collection-hand-logo"}):_c('Outline',{staticClass:"collection-bag-logo"}),_c('div',[_vm._v(" "+_vm._s(_vm._f("extractDisplayPurpose")(collection.plan))+" "),(collection.plan.date)?[_c('br'),_c('span',{staticStyle:{"font-style":"italic","font-size":"0.85em"}},[_vm._v(_vm._s(collection.plan.date.name)+", "+_vm._s(_vm.formatDate(collection.plan.date.day, "D. MMM YYYY")))])]:_vm._e()],2),(
                collection.plan.meta
                  ? collection.plan.meta.description
                    ? collection.plan.meta.description.text
                      ? true
                      : false
                    : false
                  : false
              )?_c('div',{staticClass:"info-icon",on:{"click":function($event){$event.stopPropagation();return _vm.openDetailDescription(collection)}}},[_c('span',{staticStyle:{"font-family":"Times New Roman","font-weight":"bold","font-size":"1.1em","line-height":"1.4em"}},[_vm._v("i")])]):_vm._e()],1)])}),0)])],1),_c('app-dialog',{ref:"collectionexpired",attrs:{"is-open":_vm.isCollectionExpiredDialogOpen},on:{"dialog-closed":function($event){_vm.isCollectionExpiredDialogOpen = false}}},[_c('div',{staticClass:"mt-1"},[_c('p',[_vm._v(" Der direkt verlinkte Spendenzweck ist leider nicht aktiv. Sie können gerne für eine andere Sammlung geben. ")]),_c('v-btn',{on:{"click":function($event){_vm.isCollectionExpiredDialogOpen = false}}},[_vm._v(" OK ")])],1)]),_c('app-dialog',{ref:"detailDescription",attrs:{"is-open":_vm.isDetailDescriptionDialogOpen},on:{"dialog-closed":function($event){_vm.isDetailDescriptionDialogOpen = false}}},[(
        _vm.detailCollection &&
        _vm.detailCollection.plan &&
        _vm.detailCollection.plan.meta.description.text
      )?_c('div',{staticStyle:{"text-align":"left"}},[_c('h4',{staticStyle:{"display":"flex"}},[(
            _vm.detailCollection.plan.type !== null &&
            _vm.detailCollection.plan.type === 'donation'
          )?_c('HandOutline',{staticClass:"collection-hand-logo-headline"}):_c('Outline',{staticClass:"collection-bag-logo-headline"}),_c('span',{staticClass:"detail-collection-title"},[_vm._v(_vm._s(_vm.detailCollection.plan.display.default))])],1),(_vm.detailCollection.plan.start)?_c('p',{staticStyle:{"font-size":"small"}},[_vm._v(" Kollektenzweck vom "+_vm._s(_vm.momentize(_vm.detailCollection.plan.start))+" "+_vm._s(_vm.detailCollection.plan.end ? "bis zum " + _vm.momentize(_vm.detailCollection.plan.end) : "ohne Zeitbegrenzung")+" ")]):_vm._e(),_c('div',{staticClass:"collection-list-box"},[_c('v-card',{staticClass:"collection-description",attrs:{"outlined":""}},[_c('div',{staticClass:"pa-2"},[_c('p',[_vm._v(_vm._s(_vm.detailCollection.plan.meta.description.text))]),(
                _vm.detailCollection.plan.meta.external_fields &&
                _vm.detailCollection.plan.meta.external_fields.www
              )?_c('p',[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v(" "+_vm._s(_vm.mdiWeb)+" ")]),_c('a',{attrs:{"href":_vm.detailCollection.plan.meta.external_fields.www,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.detailCollection.plan.meta.external_fields.www)+" ")])],1):_vm._e()])])],1),_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.currentOrganization ? _vm.currentOrganization.name : "")+" ")])]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.noCollectionPlanAlert),expression:"noCollectionPlanAlert"}],staticClass:"alert"},[_c('span',{staticClass:"closebtn",on:{"click":function($event){_vm.noCollectionPlanAlert = false}}},[_vm._v("×")]),_vm._v(" Diese Organisation hat noch keinen Sammlungszweck hinterlegt, deshalb können Sie momentan nicht spenden. ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-row"},[_c('div',{staticClass:"bottom-left"}),_c('div',{staticClass:"bottom-right"})])}]

export { render, staticRenderFns }