<template>
  <table>
    <tr v-if="!hideToday">
      <td>Erste Zahlung:</td>
      <td>Heute ({{ now | dateDisplay }})</td>
    </tr>
    <tr v-if="payments.first">
      <td>Folgezahlungen:</td>
      <td>
        <template v-if="payments.scheduleHuman && payments.haveMore">
          jeweils zum {{ payments.scheduleHuman }} (</template
        >{{ payments.first | dateDisplay
        }}<template v-if="payments.second"
          >, {{ payments.second | dateDisplay
          }}<template v-if="payments.haveMore">, &hellip;</template>
        </template>
        <template v-if="payments.scheduleHuman && payments.haveMore"
          >)</template
        >
      </td>
    </tr>
    <tr>
      <td>Letzte Zahlung:</td>
      <td>
        <template v-if="payments.last">{{
          payments.last | dateDisplay
        }}</template>
        <template v-else>kein Ende gesetzt</template>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <small
          >(Sie erhalten vor jeder Folgezahlung eine Benachrichtung, und können
          die Dauerspende jederzeit beenden.)</small
        >
      </td>
    </tr>
  </table>
</template>

<script>
import moment from "moment"
import { ALIGNMENT, nextValid } from "@/jscommon/src/lib/recurrence-tools"
import { dateDisplay } from "@/jscommon/src/lib/date-tools"

export default {
  name: "RecurrenceInformation",
  filters: {
    dateDisplay,
  },
  props: {
    schedule: {
      type: String,
      required: true,
    },
    end: {
      type: Object,
      required: false,
      default: null,
    },
    now: {
      type: Object,
      required: true,
    },
    hideToday: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    payments () {
      const alignment = ALIGNMENT[this.schedule]
      const retval = {
        scheduleHuman: alignment.human,
        first: null,
        second: null,
        last: null,
      }

      // Start next month
      let pointer = moment(this.now).startOf("M").add(1, "M")

      if (this.end) {
        retval.last = nextValid(moment(this.end), alignment).subtract(
          alignment.div,
          "M"
        )
        while (retval.last.isAfter(this.end)) {
          retval.last = retval.last.clone().subtract(alignment.div, "M")
        }
      }

      pointer = nextValid(pointer, alignment)
      if (!retval.last || pointer.isSameOrBefore(retval.last)) {
        retval.first = pointer

        pointer = nextValid(pointer, alignment)
        if (!retval.last || pointer.isSameOrBefore(retval.last)) {
          retval.second = pointer
        }
      }

      retval.haveMore = retval.last ? retval.last.isAfter(retval.second) : true

      return retval
    },
  },
}
</script>

<style scoped>
td {
  vertical-align: top;
  text-align: left;
}

td:first-child {
  width: 120px;
}
</style>
