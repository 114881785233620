<template>
  <div id="payment-dialog" :style="{ 'text-align': textAlign }">
    <template v-if="state === STATE_CHECK">
      <h3>Bitte warten &hellip;</h3>
      <p>Überprüfe den Status der Zahlung.</p>
    </template>
    <template v-else-if="state === STATE_DONE">
      <template v-if="thankYou.success">
        <h3>Danke!</h3>
        <div v-if="thankYouMessage">{{ thankYouMessage }}</div>
        <div v-else-if="thankYou.amount">
          Sie spendeten
          {{
            thankYou.amount
              | currency({ fractionCount: 0, symbol: "", symbolSpacing: false })
          }}
          € für "{{ thankYou.purpose }}".
        </div>
        <div v-if="thankYou.recurringPayment">
          Sie haben sich für eine sich
          {{ ALIGNMENT[thankYou.recurringPayment.schedule].name }} wiederholende
          Zahlung entschieden.
          <RecurrenceInformation
            :now="
              moment(
                thankYou.recurringPayment.valid_from ||
                  thankYou.recurringPayment.last_execution ||
                  thankYou.recurringPayment.created_at
              )
            "
            :schedule="thankYou.recurringPayment.schedule"
            :end="thankYou.recurringPayment.valid_notafter"
          />
        </div>
        <div
          v-if="state === STATE_DONE && selectedSocialMediaServices.length"
          class="d-flex justify-center mt-4"
        >
          <v-btn
            icon
            v-for="socialMediaService in selectedSocialMediaServices"
            :key="socialMediaService.key"
            target="_blank"
            :title="socialMediaService.title"
            :href="socialMediaService.href"
          >
            <v-icon>{{ socialMediaService.icon }}</v-icon>
          </v-btn>
        </div>
        <div v-if="thankYou.amount" class="mt-4">
          {{ thankYou.parishName }}
        </div>
        <div v-if="thankYou.pending">
          Hinweis:
          <template v-if="selectedMethod.integration === 'paypal'">
            Die Zahlung wurde von Paypal vorerst zurückgehalten, sollte dem
            Empfänger aber innerhalb von 21 Tagen gutgeschrieben werden.
          </template>
          <template v-else>
            Die Zahlung wurde vom Zahlungsdienstleister angenommen, aber noch
            nicht verarbeitet. Sie brauchen nichts weiter tun.
          </template>
        </div>
      </template>
      <template v-else>
        <h3>Da ist etwas schief gelaufen</h3>
        <div>Ihre Zahlung konnte leider nicht entgegengenommen werden.</div>
        <div v-if="thankYou.resultMessage">
          Fehlermeldung vom Zahlungsdienstleister:
          {{ thankYou.resultMessage }} (Code: {{ thankYou.resultCode }})
        </div>
      </template>
    </template>
    <template v-else-if="![STATE_CHECK, STATE_DONE].includes(state)">
      <template v-if="state === null">
        <v-progress-linear indeterminate color="primary" />
      </template>
      <template v-else>
        <div
          style="width: calc(100% - 5em); margin-left: auto; margin-right: auto"
        >
          <strong style="font-size: larger">{{
            paymentInput.parishName
          }}</strong>
        </div>
        <p style="font-size: 1.1em">
          Sie geben
          <v-select
            v-if="paymentInput.doRecurring"
            v-model="schedule"
            outlined
            dense
            :items="scheduleOptions"
            class="d-inline-block"
            style="max-width: 10em"
            background-color="white"
            :disabled="![STATE_INIT].includes(state)"
          />
          {{ paymentInput.amount }}&#x202F;€ für:<br />"{{
            paymentInput.collection === null
              ? ""
              : paymentInput.collection.plan | extractDisplayPurpose
          }}"
        </p>
      </template>
    </template>
    <component
      :is="outerFormComponent"
      ref="paymentForm"
      v-model="paymentFormValid"
    >
      <!-- FIXME form submit (enter) -->
      <keep-alive>
        <v-expand-transition>
          <component
            :is="selectedIntegrationComponent"
            v-if="selectedIntegrationComponent"
            ref="integrationComponent"
            :state="state"
            :selected-method="selectedMethod"
            :app-kind="appKind"
            @request-additional-fields="requestAdditionalFields"
            @goto="handleGoto"
          >
            <template #initiatingpre>
              <RecurrenceInformation
                v-if="paymentInput.doRecurring"
                style="text-align: left"
                class="mb-8"
                :schedule="schedule"
                :now="scheduleRange[0]"
                :end="scheduleRange[1]"
              />
              <PaymentChooser
                v-if="state === STATE_INIT && selectedMethod"
                v-model="selectedMethod"
                :available-methods="enabledMethods"
              />
              <v-checkbox
                v-if="enableDonationReceipt || arePersonalDataRequired"
                v-model="personData.request_donation_receipt"
                name="personData.request_donation_receipt"
                :label="donationReceiptLabel"
                :rules="rules.donationReceipt"
                hide-details="auto"
              />
              <v-checkbox
                v-if="enableEmailContact"
                v-model="personData.allow_email_contact"
                name="personData.allow_email_contact"
                label="Ich möchte mehr Informationen per E-Mail erhalten."
                hide-details="auto"
              />
              <v-text-field
                v-if="
                  personData.request_donation_receipt ||
                  additionalFields.has('name') ||
                  arePersonalDataRequired
                "
                v-model="personData.name"
                background-color="white"
                outlined
                dense
                name="billing-name"
                autocomplete="billing name"
                label="Name"
                :rules="rules.personData.name"
                class="mt-4"
              />
            </template>
            <template #initiatingpost>
              <v-text-field
                v-if="
                  personData.request_donation_receipt || arePersonalDataRequired
                "
                v-model="personData.address"
                background-color="white"
                outlined
                dense
                name="billing-address1"
                autocomplete="billing address-line1"
                label="Adresse"
                :rules="rules.personData.address"
              />
              <v-text-field
                v-if="
                  personData.request_donation_receipt || arePersonalDataRequired
                "
                v-model="personData.postcode"
                background-color="white"
                outlined
                dense
                name="billing-postal-code"
                autocomplete="billing postal-code"
                label="PLZ"
                :rules="rules.personData.postcode"
              />
              <v-text-field
                v-if="
                  personData.request_donation_receipt || arePersonalDataRequired
                "
                v-model="personData.locality"
                background-color="white"
                outlined
                dense
                name="billing-city"
                autocomplete="billing city"
                label="Stadt"
                :rules="rules.personData.locality"
              />
              <v-select
                v-if="
                  personData.request_donation_receipt || arePersonalDataRequired
                "
                v-model="personData.country"
                name="billing-country"
                autocomplete="billing country"
                outlined
                label="Land"
                background-color="white"
                :items="countryOptions"
                :rules="rules.personData.country"
              />
              <v-text-field
                v-if="
                  personData.request_donation_receipt ||
                  personData.allow_email_contact ||
                  allowConfirmationMail ||
                  paymentInput.doRecurring ||
                  arePersonalDataRequired
                "
                v-model="personData.email"
                background-color="white"
                outlined
                dense
                name="email"
                autocomplete="email"
                :label="
                  personData.request_donation_receipt ||
                  personData.allow_email_contact ||
                  paymentInput.doRecurring
                    ? 'E-Mail-Adresse'
                    : 'E-Mail-Adresse für Bestätigung (opt.)'
                "
                :rules="rules.personData.email"
              />
              <v-checkbox
                v-model="acceptLegal"
                name="acceptLegal"
                :rules="rules.acceptLegal"
                class="mt-0"
              >
                <template #label>
                  <div>
                    * Ich habe
                    <a
                      v-if="paymentInput.privacyLink"
                      :href="paymentInput.privacyLink"
                      class="link-ext"
                      target="_blank"
                      @click.stop
                      >Datenschutz
                      <v-icon x-small color="primary">
                        {{ mdiArrowTopRight }}
                      </v-icon>
                    </a>
                    <router-link
                      v-else
                      :to="{ name: 'data-privacy' }"
                      target="_blank"
                      @click.native="$event.stopImmediatePropagation()"
                    >
                      Datenschutz
                    </router-link>
                    und
                    <router-link
                      :to="{ name: 'agbs' }"
                      target="_blank"
                      @click.native="$event.stopImmediatePropagation()"
                    >
                      AGB
                    </router-link>
                    zur Kenntnis genommen
                  </div>
                </template>
              </v-checkbox>
            </template>
            <template #actions>
              <div
                style="height: 3em; text-align: left; vertical-align: bottom"
                @mouseover="formValidateUnlessHack"
              >
                <v-btn
                  v-if="state === STATE_INIT"
                  :disabled="!paymentFormValid"
                  absolute
                  right
                  color="primary"
                  @click="advanceToHandling"
                >
                  Weiter
                </v-btn>
                <v-btn
                  v-if="state === STATE_HANDLE && showOuterExecButton"
                  :disabled="!paymentFormValid"
                  absolute
                  right
                  color="primary"
                  @click="advanceToExecuting"
                >
                  Zahlung durchführen
                </v-btn>
              </div>
            </template>
            <template #footer="{ footerInfo = '' }">
              <div style="text-align: left; font-size: small">
                Bei Spenden bis zu 300 Euro wird Ihr Kontoauszug vom Finanzamt
                als Spendenbescheinigung anerkannt.
                <span v-if="state !== STATE_INIT" v-html="footerInfo"></span>
              </div>
            </template>
          </component>
          <v-progress-circular v-else indeterminate color="primary" />
        </v-expand-transition>
      </keep-alive>
    </component>
  </div>
</template>
<script>
import PaymentFragmentDirect from "./PaymentFragmentDirect"
import PaymentFragmentPaypal from "./PaymentFragmentPaypal"
import PaymentFragmentOppwa from "./PaymentFragmentOppwa"
import PaymentChooser from "./PaymentChooser"
import { mapGetters, mapState } from "vuex"
import countries from "i18n-iso-countries"
import { email, required, validTld } from "../lib/validator-tools"
import { extractDisplayPurpose } from "../lib/regex-tools"
import {
  mdiArrowTopRight,
  mdiShareVariant,
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiEmail,
  mdiWhatsapp,
  mdiPinterest,
  mdiSnapchat,
  mdiLinkedin,
} from "@mdi/js"
import {
  SET_PAYMENT_STATE,
  SET_INTEGRATION_DATA,
  SET_PAYMENT_RESULT,
  SET_PERSON_DATA,
  SET_PAYMENT_STATE_INITIAL,
  SET_MANDATE_ID,
  SET_MEMO_TEXT,
} from "../store/payment/mutation-types"
import {
  STATE_INIT,
  STATE_HANDLE,
  STATE_EXEC,
  STATE_DONE,
  STATE_CHECK,
} from "../store/payment/state-types"
import { VContainer, VForm } from "vuetify/lib/components"
import RecurrenceInformation from "@/jscommon/src/components/RecurrenceInformation"
import {
  ALIGNMENT,
  availableOptions,
} from "@/jscommon/src/lib/recurrence-tools"
import moment from "moment"
import { templateVariables } from "@/jscommon/src/lib/template-tools"
import { getPaymentTypeHuman } from "@/jscommon/src/lib/payment-types-db"

countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

const DEFAULT_PERSON_DATA = {
  email: "",
  name: "",
  address: "",
  postcode: "",
  locality: "",
  country: "DE",
  request_donation_receipt: false,
  allow_email_contact: false,
}

export default {
  name: "PaymentForm",
  components: {
    RecurrenceInformation,
    PaymentChooser,
  },
  filters: {
    extractDisplayPurpose,
  },
  props: {
    appKind: {
      validator: (x) => ["spende.app", "kollekte.app"].includes(x),
      default: "kollekte.app",
    },
    mode: {
      validator: (x) => [null, "pay", "check"].includes(x),
      default: null,
    },
    input: {
      type: Object,
      default: null,
    },
    textAlign: {
      type: String,
      default: "center",
    },
  },
  data: function () {
    return {
      selectedMethod: null,
      paymentFormValid: false,
      additionalFields: new Set(),
      acceptLegal: false,
      schedule: null,
      personData: {
        ...DEFAULT_PERSON_DATA,
      },
      mdiArrowTopRight,
      mdiShareVariant,
      moment,
      STATE_INIT,
      STATE_HANDLE,
      STATE_CHECK,
      STATE_DONE,
      ALIGNMENT,
    }
  },
  computed: {
    ...mapGetters("payment", ["enabledMethods", "thankYou"]),
    ...mapState("payment", ["state", "donationReceiptConfiguration"]),
    ...mapState("payment", {
      paymentInput: "input",
    }),
    ...mapState(["now"]),
    ...mapGetters("display", ["currentOrganization"]),
    selectedIntegrationComponent () {
      switch (this.selectedMethod?.integration ?? null) {
        case "oppwa":
          return PaymentFragmentOppwa
        case "paypal":
          return PaymentFragmentPaypal
        case "direct":
          return PaymentFragmentDirect
      }
      return null
    },
    personDataCleaned: function () {
      // Ensure that no unconfirmed data ends up in personData
      const retval = {
        request_donation_receipt: this.personData.request_donation_receipt,
        allow_email_contact: this.personData.allow_email_contact,
      }
      if (
        retval.allow_email_contact ||
        retval.request_donation_receipt ||
        this.allowConfirmationMail
      ) {
        retval.email = this.personData.email
      }
      for (const fieldName of [
        "name",
        "address",
        "postcode",
        "locality",
        "country",
      ]) {
        if (retval.request_donation_receipt) {
          retval[fieldName] = this.personData[fieldName]
        }
      }
      return retval
    },
    additionalDataCleaned () {
      const retval = {}
      for (const fieldName of [
        "name",
        "address",
        "postcode",
        "locality",
        "country",
      ]) {
        if (this.additionalFields.has(fieldName)) {
          retval[fieldName] = this.personData[fieldName]
        }
      }
      if (this.paymentInput.doRecurring) {
        retval.schedule = this.schedule
        retval.email = this.personData.email
      }
      return retval
    },
    currentDonationReceiptConfigurations () {
      return this.donationReceiptConfiguration.filter((config) => {
        return (
          (!config.valid_from ||
            moment(this.now).isSameOrAfter(config.valid_from)) &&
          (!config.valid_notafter ||
            moment(this.now).isBefore(config.valid_notafter))
        )
      })
    },
    enableDonationReceipt () {
      // Always if recurring
      if (this.paymentInput.doRecurring) {
        return true
      }

      // Find config
      const config = this.currentDonationReceiptConfigurations.length
        ? this.currentDonationReceiptConfigurations[0]
        : null
      const donationType =
        this.paymentInput.collection?.plan?.type ?? "collection"

      // Apply config
      if (config) {
        if (["donation", "collection"].includes(donationType)) {
          const minAmount =
            donationType === "donation"
              ? config.min_amount_donation
              : config.min_amount_collection
          return minAmount !== null && minAmount <= this.paymentInput.amount
        }
      }

      // Apply default
      return (
        (this.paymentInput.amount >= 25 && donationType === "donation") ||
        (this.paymentInput.amount >= 300 &&
          (this?.paymentInput?.features?.donation_receipt_for_collection ??
            false))
      )
    },
    enableEmailContact () {
      return this?.paymentInput?.features?.allow_email_contact ?? false
    },
    donationReceiptLabel () {
      if (
        (this.paymentInput.collection?.plan?.type ?? "collection") ===
        "collection"
      ) {
        return "Ich möchte die Anonymität der Kollekte aufheben und bitte um eine Spendenbestätigung."
      }

      if (this.arePersonalDataRequired) {
        return "Ich möchte der oben genannten Organisation meine Daten zum Ausstellen einer Spendenbescheinigung zur Verfügung stellen."
      }
      return "Ich bin mit der Weitergabe meiner Daten für die Übersendung einer Spendenbescheinigung einverstanden."
    },
    allowConfirmationMail () {
      return this?.paymentInput?.features?.allow_confirmation_mail ?? true
    },
    arePersonalDataRequired () {
      return this?.paymentInput?.features?.require_pdata ?? false
    },
    rules () {
      const retval = {
        personData: {
          email: [],
          name: [],
          address: [],
          postcode: [],
          locality: [],
          country: [],
        },
        acceptLegal: [required("Zustimmung erforderlich")],
        donationReceipt: [],
      }
      if (
        this.personData.allow_email_contact ||
        this.personData.request_donation_receipt ||
        this.allowConfirmationMail ||
        this.paymentInput.doRecurring
      ) {
        retval.personData.email.push(
          email("Ungültige E-Mail-Adresse"),
          validTld("Ungültige Domainendung")
        )
        if (
          this.personData.allow_email_contact ||
          this.personData.request_donation_receipt ||
          this.paymentInput.doRecurring
        ) {
          retval.personData.email.push(required("Feld verpflichtend"))
        }
      }
      for (const fieldName of [
        "name",
        "address",
        "postcode",
        "locality",
        "country",
      ]) {
        if (
          this.personData.request_donation_receipt ||
          this.additionalFields.has(fieldName) ||
          this.arePersonalDataRequired
        ) {
          retval.personData[fieldName].push(required("Feld verpflichtend"))
        }
      }

      if (this.arePersonalDataRequired) {
        retval.donationReceipt.push(required("Feld verpflichtend"))
      }

      return retval
    },
    countryOptions () {
      return Object.entries(
        countries.getNames("de", { select: "official" })
      ).map(([value, text]) => {
        return { value, text }
      })
    },
    scheduleOptions () {
      return availableOptions(this.scheduleRange[0], this.scheduleRange[1])
    },
    scheduleRange () {
      return [
        moment(),
        this.paymentInput.collection?.plan?.end
          ? moment(this.paymentInput.collection.plan.end)
          : null,
      ]
    },
    outerFormHackActive () {
      // HACK HACK HACK
      // Oppwa COPY+PAY creates a dynamic form. This is not compatible with our outer v-form
      return (
        [STATE_HANDLE, STATE_EXEC, STATE_CHECK].includes(this.state) &&
        this.selectedMethod?.integration === "oppwa"
      )
    },
    outerFormComponent () {
      if (this.outerFormHackActive) {
        return VContainer
      } else {
        return VForm
      }
    },
    showOuterExecButton () {
      // HACK
      return this.selectedMethod?.integration === "direct"
    },
    thankYouMessage () {
      const defaultMessage =
        'Sie spendeten %Spendenbetrag%%Währungssymbol% für "%Sammlungszweck%". Vielen Dank!'
      const templateText =
        this.currentOrganization.settings_inherit?.WS?.thank_you?.text ??
        defaultMessage
      if (!templateText) {
        return ""
      }

      return this.renderTemplateText(templateText)
    },
    socialMediaShareMessage () {
      const defaultMessage =
        'Ich habe gerade %Spendenbetrag%%Währungssymbol% für "%Sammlungszweck%" an %Organisationsname% gespendet. Spende Du doch auch! %Direktlink%'
      const templateText =
        this.currentOrganization.settings_inherit?.WS?.thank_you_share?.text ??
        defaultMessage
      if (!templateText) {
        return ""
      }
      return this.renderTemplateText(templateText)
    },
    selectedSocialMediaServices () {
      const socialMediaServices = {
        email: {
          position: 0,
          key: "email",
          title: "E-Mail",
          icon: mdiEmail,
          href:
            "mailto:?body=" + encodeURIComponent(this.socialMediaShareMessage),
        },
        whatsapp: {
          position: 1,
          key: "whatsapp",
          title: "WhatsApp",
          icon: mdiWhatsapp,
          href:
            "https://api.whatsapp.com/send?text=" +
            encodeURIComponent(this.socialMediaShareMessage),
        },
        facebook: {
          position: 2,
          key: "facebook",
          title: "Facebook",
          icon: mdiFacebook,
          href:
            "https://www.facebook.com/sharer/sharer.php?u=" +
            encodeURIComponent(window.location.href),
        },
        instagram: {
          position: 3,
          key: "instagram",
          title: "Instagram",
          icon: mdiInstagram,
          href: "https://www.instagram.com/",
        },
        twitterx: {
          position: 4,
          key: "twitterx",
          title: "X (Twitter)",
          icon: mdiTwitter,
          href:
            "https://twitter.com/intent/tweet?text=" +
            encodeURIComponent(this.socialMediaShareMessage),
        },
        pinterest: {
          position: 5,
          key: "pinterest",
          title: "Pinterest",
          icon: mdiPinterest,
          href:
            "https://www.pinterest.com/pin/create/link/?url=" +
            encodeURIComponent(window.location.href) +
            "&description=" +
            encodeURIComponent(this.socialMediaShareMessage),
        },
        snapchat: {
          position: 6,
          key: "snapchat",
          title: "Snapchat",
          icon: mdiSnapchat,
          href: "https://www.snapchat.com/",
        },
        linkedin: {
          position: 7,
          key: "linkedin",
          title: "LinkedIn",
          icon: mdiLinkedin,
          href:
            "https://www.linkedin.com/shareArticle?mini=true&url=" +
            encodeURIComponent(window.location.href) +
            "&summary=" +
            encodeURIComponent(this.socialMediaShareMessage) +
            "&title=" +
            encodeURIComponent("Meine Spende"),
        },
      }
      const selectedSocialMediaServicesKeys = Object.entries(
        this.currentOrganization.settings_inherit?.WS?.social_media ?? {}
      ).filter(([_serviceName, isActive]) => isActive)

      return selectedSocialMediaServicesKeys
        .map(([serviceName, _isActive]) => socialMediaServices[serviceName])
        .sort((serviceA, serviceB) => serviceA.position - serviceB.position)
    },
  },
  watch: {
    enabledMethods: {
      immediate: true,
      handler (newValue) {
        if (newValue && newValue.length > 0 && !this.selectedMethod) {
          this.selectedMethod = newValue[0]
        }
      },
    },
    state: {
      immediate: true,
      handler (newVal) {
        if (newVal === null || newVal === STATE_INIT) {
          this.resetFields()
        }
      },
    },
    schedule: {
      immediate: true,
      handler (newVal) {
        if (
          this.scheduleOptions &&
          this.scheduleOptions.length > 0 &&
          !newVal
        ) {
          this.schedule = "monthly"
        }
      },
    },
    mode: {
      immediate: true,
      async handler (newVal, oldVal) {
        await this.$nextTick()

        if (!newVal) {
          await Promise.allSettled([
            this.$store.commit("payment/" + SET_MANDATE_ID, {
              mandateId: null,
            }),
            this.$store.commit("payment/" + SET_MEMO_TEXT, { memoText: null }),
          ])
        }

        if (newVal === "pay" && !oldVal) {
          this.resetFields()
          await this.$store.commit("payment/" + SET_PAYMENT_STATE, null)
          await this.$store.commit(
            "payment/" + SET_PAYMENT_STATE_INITIAL,
            this.input
          )
        } else if (newVal === "check") {
          if (!oldVal) {
            // HACK HACK HACK We need to guess the integration type
            // Can only be paypal or oppwa, since direct doesn't unload the page
            this.selectedMethod = this.$store.state.route.query?.id
              ? {
                integration: "oppwa",
                method: null, // Warning: Integration must be ready to receive method null
              }
              : {
                integration: "paypal",
                method: "paypal",
              }
            await this.$store.commit("payment/" + SET_INTEGRATION_DATA, {
              selectedIntegration: this.selectedMethod,
              data: {
                onlinePaymentId:
                  this.$store.state.route?.params?.onlinePaymentId || null,
              },
            })
          }
          await this.$store.commit("payment/" + SET_PAYMENT_STATE, STATE_CHECK)
        }
      },
    },
  },
  methods: {
    requestAdditionalFields (additionalFields) {
      this.additionalFields = new Set(additionalFields)
    },
    resetFields () {
      this.personData = {
        ...DEFAULT_PERSON_DATA,
      }
      this.acceptLegal = false
      this.additionalFields = new Set()
      this.selectedMethod =
        this.enabledMethods && this.enabledMethods.length > 0
          ? this.enabledMethods[0]
          : null
    },
    async advanceNext () {
      if (this.state === STATE_INIT) {
        await this.handleGoto(STATE_HANDLE)
      } else if (this.state === STATE_HANDLE) {
        await this.handleGoto(STATE_EXEC)
      }
    },
    async handleGoto (next, args) {
      if (next === STATE_HANDLE) {
        await this.advanceToHandling()
      } else if (next === STATE_EXEC) {
        await this.advanceToExecuting()
      } else if (next === STATE_DONE) {
        await this.advanceToDone(args || {})
      } else {
        throw new Error(`Invalid transition ${next}`)
      }
    },
    async advanceToHandling () {
      const personData = {
        ...this.personDataCleaned,
        accept_legal: this.acceptLegal,
      }
      const additionalData = { ...this.additionalDataCleaned }
      const selectedMethod = this.selectedMethod

      await this.$store.commit("payment/" + SET_PERSON_DATA, { personData })
      await this.$store.commit("payment/" + SET_INTEGRATION_DATA, {
        selectedMethod,
        data: { additionalData },
      })
      await this.$store.commit("payment/" + SET_PAYMENT_STATE, STATE_HANDLE)
    },
    async advanceToExecuting () {
      this.formValidateUnlessHack()
      if (this.outerFormHackActive || this.paymentFormValid) {
        await this.$store.commit("payment/" + SET_PAYMENT_STATE, STATE_EXEC)
      }
    },
    async advanceToDone (args) {
      await this.$store.commit("payment/" + SET_PAYMENT_RESULT, args || {})
      await this.$store.commit("payment/" + SET_PAYMENT_STATE, STATE_DONE)
    },
    formValidateUnlessHack () {
      if (!this.outerFormHackActive) {
        this.$refs.paymentForm.validate()
      }
    },
    renderTemplateText (initialTemplateText) {
      let templateText = initialTemplateText
      Object.entries(templateVariables).forEach(([_key, templateVariable]) => {
        if (!templateVariable.isActive) {
          return
        }
        if (templateVariable.fixedValue) {
          templateText = templateText.replace(
            `%${templateVariable.key}%`,
            templateVariable.fixedValue
          )
        } else if (templateVariable.replacement) {
          let val = this
          templateVariable.replacement.split(".").forEach((keyPart) => {
            val = val[keyPart]
          })

          if (templateVariable?.type === "amount") {
            val = this.$options.filters.currency(val, {
              fractionCount: 0,
              symbol: "",
              symbolSpacing: false,
            })
          }

          if (templateVariable.key === "payment_type") {
            val = getPaymentTypeHuman(val)
          }

          templateText = templateText.replace(`%${templateVariable.key}%`, val)
        }
      })
      return templateText
    },
  },
}
</script>
