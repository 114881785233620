<template>
  <div class="branded-header">
    <DefaultCorner v-if="showBrand" class="branded-header-logo" /><br />
    <div class="logo-spacer" />
    <slot />
  </div>
</template>

<script>
import DefaultCorner from "@/components/icon/DefaultCorner"

export default {
  components: {
    DefaultCorner,
  },
  props: {
    showBrand: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped lang="stylus">
.branded-header
  text-align: left
  align: left
  font-size: small
  color: rgba(0,0,0,0.54)

.logo-spacer
  display: block
  height: 1em

.branded-header-logo
  position: absolute
  left: 1.5em
  top: 1.5em
  width: 2.8em
  float: left
</style>
