<template>
  <v-app id="app">
    <header>
      <div id="inner_header">
        <template v-if="this.$store.state.singleCollection || modeEmbed">
          <LabelLogo />
        </template>
        <template v-else>
          <router-link to="/">
            <LabelLogo />
          </router-link>
        </template>
      </div>
    </header>
    <main>
      <router-view />
    </main>
    <!-- <footer>
      <span class="nobreak">kollekte.app</span> · <span class="nobreak">digital.wolff</span> · <span class="nobreak">Klosterstr. 66</span> · <span class="nobreak">10179 Berlin-Mitte</span> · <span class="nobreak"><a href="mailto:kollekteapp@digitalwolff.de">kollekteapp@digitalwolff.de</a></span>
    </footer> -->
  </v-app>
</template>
<script>
import LabelLogo from "@/components/icon/LabelLogo"
import isModeEmbedMixin from "@/mixins/isModeEmbedMixin"

function updateVh () {
  // Via https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`)
}

export default {
  components: {
    LabelLogo,
  },
  mixins: [isModeEmbedMixin],
  created () {
    // reset collection direct link state
    this.$store.commit("setSingleCollection", false)

    updateVh()
    // We listen to the resize event
    window.addEventListener("resize", () => {
      updateVh()
    })

    const html = document.documentElement //
    html.setAttribute("lang", "de")

    this.$store.dispatch("startTimer")
  },
  mounted () {
    updateVh()
    window.setTimeout(updateVh, 1000) // Sanity fallback, recompute after 1s, for the iframe case
  },
}
</script>
<style lang="stylus">
#app
  position: fixed
  height: 100vh
  width: 100vw
  overflow: hidden

  .sweet-action-close:hover
    background-color: var(--kollekteapp-primary-color) !important

header
  position:absolute
  width: 100%
  padding: 5px
  #inner_header
    max-width: 50em
    margin-left: auto
    margin-right: auto
  .label_logo
    margin-top: 5px
    margin-left: 5px
    max-width: 150px
    width: 45vw
  margin-bottom: 0
body
  display: flex
  flex-direction: column
footer
  text-align: center
  vertical-align: bottom
  position: absolute
  bottom: 0
  left: 0
  right: 0

  .nobreak
    white-space: nowrap

  @media (max-height: 76em)
    background-color: rgba(255, 255, 255, 0.7)

::selection
  color: white !important
header
  h1
    font-size: 28px;
    margin: 0
    line-height: 1
  h3
    padding-top: 7px;
    font-size: 18px;
    margin: 0
    line-height: 1
</style>
