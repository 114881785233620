import {
  SET_COLLECTIONS_FOR_URL,
  SET_PAYMENT_INFORMATION_FOR_URL,
  SET_ORGANIZATIONS,
  SET_COORDS,
  SET_EXTENSIONS_FOR_PLANS,
} from "@/store/mutation-types"

export default {
  /**
   *
   * @param { object } state
   * @param { Array<{id: string, name: string, level: string, parent: string}> } organizations
   */
  [SET_ORGANIZATIONS] (state, { organizations }) {
    state.organizations = organizations
  },
  [SET_COLLECTIONS_FOR_URL] (state, { organizationUrl, collections }) {
    state.collectionsForUrl[organizationUrl] = collections
  },
  [SET_EXTENSIONS_FOR_PLANS] (state, { extensions }) {
    state.extensions = extensions
  },
  [SET_PAYMENT_INFORMATION_FOR_URL] (
    state,
    { organizationUrl, paymentInformation }
  ) {
    state.paymentInformationForUrl[organizationUrl] = paymentInformation
  },
  [SET_COORDS] (state, { latitude, longitude }) {
    state.coords = { latitude, longitude }
  },
}
