<template>
  <div id="home">
    <vue-headful v-if="!ready" title="kollekte.app" />
    <vue-headful v-else :title="parishName + ' — kollekte.app'" />
    <div id="button-layer">
      <div id="button-layer-inner">
        <div id="about-button" @click="isAboutDialogOpen = true">
          <QuestionMark id="about-icon" />
        </div>
      </div>
    </div>
    <div id="home-container">
      <klingelbeutel
        @start-payment="startPayment"
        @change-parish="selectParish"
      />
      <app-dialog
        ref="paymentDialog"
        :is-open="paymentFormMode !== null"
        max-width="500px"
        @dialog-closed="abortPayment"
      >
        <payment-form
          app-kind="kollekte.app"
          :input="paymentInput"
          :mode="paymentFormMode"
        />
      </app-dialog>
      <app-dialog
        ref="parishDialog"
        :hide-overflow-x="true"
        :is-open="isParishDialogOpen"
        @dialog-closed="isParishDialogOpen = false"
      >
        <ParishForm />
      </app-dialog>
      <app-dialog
        ref="aboutDialog"
        :is-open="isAboutDialogOpen"
        @dialog-closed="isAboutDialogOpen = false"
      >
        <div class="about-dialog">
          <h1>Über kollekte.app</h1>
          <p>
            Mit dieser Anwendung können Sie der teilnehmenden Einrichtung /
            Organisation eine Kollekte geben. Diese gelangt direkt auf das
            zugehörige Kollektenkonto und wird von da aus dem Spendenzweck
            zugewiesen.
          </p>
          <p>
            Haben Sie Fragen, technische Probleme oder einen Bug entdeckt?<br /><a
              class="app-link"
              href="mailto:kollekteapp@digitalwolff.de"
              >kollekteapp@digitalwolff.de</a
            >
          </p>
          <p v-if="privacyLink">
            Verantwortlich für den Datenschutz ist die teilnehmende Einrichtung:
            <a :href="privacyLink" class="link-ext" target="_blank"
              >Datenschutzerklärung</a
            >.
          </p>
        </div>
        <v-divider />
        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <div>
              <span class="app-version">Version {{ appVersion }}</span>
              <router-link to="/agb"> AGB </router-link> &ndash;
              <template v-if="!privacyLink">
                <router-link to="/datenschutz"> DATENSCHUTZ </router-link>
                &ndash;
              </template>
              <span class="impressum-button" @click="openImpressumDialog"
                >IMPRESSUM</span
              >
            </div>
          </v-col>
        </v-row>
      </app-dialog>
      <app-dialog
        ref="impressumDialog"
        :is-open="isImpressumDialogOpen"
        @dialog-closed="closeImpressumDialog"
      >
        <div class="impressum-dialog">
          <h1>Impressum</h1>
          <p>kollekte.app ist eine Marke der Digital.Wolff, Plötz & Co GmbH.</p>
          <p>
            Die Digital.Wolff GmbH ist Betreiber dieser Web-App und Vermittler
            der Zahlungsdienstleistung. Alle Zahlungen gehen direkt auf das
            Konto der teilnehmenden Einrichtung.
          </p>
          <h2>Angaben gemäß § 5 TMG</h2>
          <p>
            Digital.Wolff, Plötz & Co GmbH<br />
            Luckenwalder Straße 4-6<br />
            10963 Berlin<br />
            <br />
            <a class="app-link" href="https://digitalwolff.de"
              >digitalwolff.de</a
            ><br />
            <a class="app-link" href="mailto:kollekteapp@digitalwolff.de"
              >kollekteapp@digitalwolff.de</a
            ><br />
            <br />
            Geschäftsführer: Dr. Martin C. Wolff, Henryk Plötz, Hannah Jo
            Wolff<br />
            <br />
            Amtsgericht Charlottenburg: HRB 192569 B. USt-ID: DE315450913.<br />
            <br />
            Vertretungsberechtigt verantwortlich für den Inhalt nach § 55 Abs. 2
            RStV ist Dr. Martin C. Wolff.
          </p>
        </div>
      </app-dialog>
    </div>
    <!-- <div class="modal-container">
          <img id="branding_logo-dialog" src="@/assets/kkbs_logo_lang.png"><br>
          <div id="logo-spacer"></div>
          <div class="list-box">
          <router-link v-for="parish in orderedParishList" :key="parish.id" :to="{ name: 'parish-selected', params: {'parishId': parish.id}}" class="parishListItem" active-class="active"><img id="branding_logo-parish" src="@/assets/ekbo_submarke_4c_farbig.svg"> {{parish.name | shortenParish}}<div id="horizontal-line"></div></router-link></div></div> -->
  </div>
</template>

<script>
import Klingelbeutel from "@/components/Klingelbeutel"
import PaymentForm from "@/jscommon/src/components/PaymentForm"
import ParishForm from "@/components/ParishForm"
import QuestionMark from "@/components/QuestionMark"
import { transformGermanAkkusativ } from "@/jscommon/src/lib/regex-tools"
import { SET_PARISH_ID } from "@/store/action-types"
import { mapGetters, mapState } from "vuex"
import brands from "@/brands"
import AppDialog from "@/jscommon/src/components/AppDialog"

export default {
  name: "Home",
  components: {
    Klingelbeutel,
    PaymentForm,
    ParishForm,
    QuestionMark,
    AppDialog,
  },
  filters: {
    transformGermanAkkusativ,
  },
  data () {
    return {
      paymentFormMode: null,
      paymentInput: {},
      isAboutDialogOpen: false,
      isImpressumDialogOpen: false,
      isParishDialogOpen: false,
    }
  },
  computed: {
    appVersion: function () {
      return process.env.PACKAGE_VERSION
    },
    brands: function () {
      return brands
    },
    ...mapGetters("display", ["ready", "parishName"]),
    ...mapGetters("payment", ["thankYou"]),
    ...mapState("payment", {
      paymentState: "state",
    }),
    privacyLink: function () {
      return (
        (
          (this.$store.getters["data/organizationSettingsByUrl"] ?? {})[
            this.$store.getters["display/currentOrganization"]?.url
          ] ?? {}
        )?.WA?.privacy_url ?? null
      )
    },
    showPaymentDialog () {
      return this.paymentFormMode !== null
    },
  },
  watch: {
    async $route (to, from) {
      this.isParishDialogOpen = false
      await this._intInitialActions()
    },
  },
  async mounted () {
    // document.getElementById('home').style.setProperty('--kollekteapp-primary-color', this.$store.getters.currentTheme.color)
    await this._intInitialActions()
  },
  methods: {
    _intInitialActions: async function () {
      if (this.$route.params) {
        if (
          this.$store.state.display.parishId !== this.$route.params.parishId
        ) {
          await this.$store.dispatch(
            "display/" + SET_PARISH_ID,
            this.$route.params.parishId
          )
        }
      }
      if (!this.$store.state.data.organizations.length) {
        await this.$store.dispatch("data/fetchOrganizations")
      }
      if (this.$route.name === "done") {
        this.paymentFormMode = "check"
      }
    },
    startPayment: async function (paymentInput) {
      this.paymentInput = paymentInput
      this.paymentFormMode = "pay"
    },
    abortPayment: async function () {
      this.paymentFormMode = null
      await this.$nextTick()
      await this.goHome()
    },
    checkPayment: async function () {
      this.paymentFormMode = "check"
    },
    selectParish () {
      this.isParishDialogOpen = true
    },
    closeParishSelection () {
      this.isParishDialogOpen = false
    },
    goHome: async function () {
      if (this.$route.name !== "parish-selected") {
        await this.$router.replace({
          name: "parish-selected",
          params: { organizationId: this.$store.state.display.organizationId },
        })
      }
    },
    openImpressumDialog () {
      this.isAboutDialogOpen = false
      this.isImpressumDialogOpen = true
    },
    closeImpressumDialog () {
      this.isImpressumDialogOpen = false
    },
  },
}
</script>

<style lang="stylus">
@import '../styles/main'
#home

  ::-moz-selection
    background: var(--kollekteapp-primary-color)

  ::selection
    background: var(--kollekteapp-primary-color)

  a
    color: var(--kollekteapp-primary-color)

  #button-layer
    position: fixed
    width: 100vw
    height: 100vh /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100) /* Set in App.vue */
    margin-left: auto
    margin-right: auto
    pointer-events: none

    #button-layer-inner
      height: 100%
      max-width: 23em
      max-height: 45em
      margin-left: auto
      margin-right: auto
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: flex-end
      text-align: center

      #about-button
        margin-left: 1em
        margin-bottom: 2em
        width: 2em
        height: 2em
        cursor: pointer
        pointer-events: auto
        text-align: center

        span
          font-size: 2.5em

  .about-dialog, .impressum-dialog
    h1
      font-size: 1.4em

    h2
      font-size: 1.2em

  .about-dialog
    p
      text-align: justify

  .app-version
    font-size: 0.8em
    color: #ccc
    padding-right: 2em
    padding-left: 2em

  .impressum-dialog
    p
      text-align: left

  .list-box
    position: relative
    display: block
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3)
    background: rgba(255, 255, 255, 0.8)
    overflow-y: scroll
    height: 29.5em
    text-align: center

  .list-outline
    position: relative
    padding: 1em
    background: rgba(255, 255, 255, 0.8)

  #home-container
    margin: 18px 36px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;

  h3
    color: $clr-secondary-text-light

  #horizontal-line
    background: rgba(0, 0, 0, 0.2)
    height: 1px

  .thankYouClose
    box-shadow: 3px 7px 8px rgba(0, 0, 0, 0.2)

  #about-icon
    border-radius: 100%
    width: 1.8em
    height: 1.8em
    padding: 0em
    margin: 0em
    background: var(--kollekteapp-primary-color)
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5)

.impressum-button
  cursor: pointer
  text-transform: uppercase
  color: var(--kollekteapp-primary-color)
</style>
