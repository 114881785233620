export default function () {
  return {
    state:
      null /* null, 'initiating', 'handling', 'executing', 'checking', 'done' */,
    result: {
      successful: false,
      message: null,
      data: null,
    },

    input: {
      parishId: null,
      collection: null, // object
      parishName: null,
      merchantMemo: null,
      paymentInformation: null,
      amount: 0,
      privacyLink: null,
      features: {},
      doRecurring: false,
    },

    /* State */
    personData: null,
    selectedIntegration: null, // { method, integration }
    integrationData: null,
    donationReceiptConfiguration: [],
    memoText: null,
    mandateId: null,
  }
}

/*
 Call from outside: start payment flow (amount, purpose, organization, repeating?)
 Transition null -> initiating
    Show Chooser, filter by availability x repeating
      if only one: fix select, no chooser
    Input additional data (as determined by selected integration)
    Next: Outer button.
  Transition initiating -> handling
    Integration may show additional internal queries
    Integration may directly skip ahead
    Next: programmatic, or outer button
  Transition handling -> executing
    Generic UI, payment is executed
      Integration UI slot
    May exit SPA and use external redirect based payment flow
      Return: Directly go to executing->done
    Next: programmatic, or page load
    May fall back to handling, if correctable error exists
  Transition null -> checking
    Check oppwa payment
  Transition executing or checking -> done
    Generic UI, thank you
      Integration UI slot
 */
