<template>
  <!-- v-show="primaryColor !== null" -->
  <div id="kb-main" class="main">
    <div class="logo-layer">
      <div class="top-row">
        <div class="top-left" />
        <div class="top-right">
          <DefaultCorner id="branding_logo" />
        </div>
      </div>
      <div class="bottom-row">
        <div class="bottom-left" />
        <div class="bottom-right" />
      </div>
    </div>
    <div class="controls">
      <div class="display-row control-row">
        <div class="display-container">
          <div class="arrowLeft">
            <div
              v-if="
                ready &&
                collections.length > 1 &&
                !this.$store.state.singleCollection
              "
              class="arrowDiv clickable"
              @click="moveToPreviousCollection"
            />
          </div>
          <div class="display">
            <div style="display: flex">
              <div style="min-width: 2.1em" />
              <div class="amount clickable" @click="selectCustomValue">
                <span v-if="value < 200">{{ value }}</span>
                <div v-else class="custom-amount">
                  <input
                    ref="customAmount"
                    v-model.number="customValue"
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="customAmount"
                    min="1"
                    :max="inputMax"
                  />
                </div>
                &#x202F;€
              </div>
              <div v-if="currentCollection" style="width: 2.1em">
                <div
                  v-if="
                    currentCollection.plan
                      ? currentCollection.plan.meta
                        ? currentCollection.plan.meta.description
                          ? currentCollection.plan.meta.description.text
                            ? true
                            : false
                          : false
                        : false
                      : false
                  "
                  class="info-icon"
                  @click="openDetailDescription(currentCollection)"
                >
                  <span
                    style="
                      font-family: Times New Roman;
                      font-weight: bold;
                      font-size: 1.1em;
                      line-height: 1.4em;
                    "
                    >i</span
                  >
                </div>
              </div>
            </div>
            <div
              :class="{
                preposition: true,
                clickable: !this.$store.state.singleCollection && ready,
              }"
              @click="startSelectCollection"
            >
              {{ collectionDisplay.preposition }}
            </div>
            <div
              :class="{
                purpose: true,
                clickable: !this.$store.state.singleCollection && ready,
              }"
              :style="{
                height: (collectionDisplay.preposition === '' ? 76 : 58) + 'px',
              }"
              @click="startSelectCollection"
            >
              <div
                v-if="ready"
                id="purposeSpan"
                :style="[
                  'display: block;',
                  {
                    lineHeight:
                      (purposeFontSize === null ? 10 : purposeFontSize) +
                      2 +
                      'px',
                    fontSize:
                      (purposeFontSize === null ? 10 : purposeFontSize) + 'px',
                  },
                ]"
                v-html="fixNewlines(escapeHtml(collectionDisplay.purpose))"
              />
              <span v-else>{{ collectionDisplay.purpose }}</span>
            </div>
          </div>
          <div class="arrowRight">
            <div
              v-if="
                ready &&
                collections.length > 1 &&
                !this.$store.state.singleCollection
              "
              class="arrowDiv clickable"
              @click="moveToNextCollection"
            />
          </div>
        </div>
        <div
          v-if="ready && !this.$store.state.singleCollection"
          class="display-dots"
        >
          <span
            v-for="(collection, index) in collections"
            :key="collection.id"
            class="purposeDotSelection clickable"
            @click="selectCollection($event, index)"
          >
            <span v-if="ready && collections.length > 1"
              ><span v-if="index === collectionIndex" class="purposeDots"
                >&#10687;</span
              ><span v-else class="purposeDots">&#9675;</span></span
            >
          </span>
        </div>
      </div>
      <div class="calendar-row control-row">
        <template
          v-if="
            currentCollection &&
            currentCollection.plan &&
            currentCollection.plan.date
          "
        >
          {{ currentCollection.plan.date.name }},
          {{ formatDate(currentCollection.plan.date.day, "D. MMM YYYY") }}
        </template>
      </div>
      <div class="slider-row control-row">
        <div id="value-slider" class="slider">
          <vue-slider
            v-model="value"
            :tooltip="value < 200 ? 'always' : 'none'"
            height="0.7em"
            dot-size="22"
            :min="1"
            :max="Math.max(200, value)"
          />
        </div>
        <div class="moreLabel clickable" @click="selectCustomValue($event)">
          mehr
        </div>
      </div>
      <div v-if="!hideParishSelector" class="parish-row control-row">
        <div
          v-if="ready"
          :class="{
            parish: true,
            clickable: !this.$store.state.singleCollection,
          }"
          @click="selectParish"
        >
          {{ parishName }}
        </div>
        <div v-else class="parish clickable" @click="selectParish">
          &mdash; Gemeinde wählen &mdash;
        </div>
      </div>
      <div class="paybutton-row control-row">
        <div class="paybutton-container clickable">
          <div class="start-payment" @click="startPayment">
            {{ collectionTypeForDisplay }} geben
          </div>
        </div>
      </div>
    </div>
    <app-dialog
      ref="collectionDialog"
      :is-open="isCollectionDialogOpen"
      @dialog-closed="isCollectionDialogOpen = false"
    >
      <branded-dialog-header>
        <h4>{{ parishName }}</h4>
        Wählen Sie den Kollekten- oder Spendenzweck:<br /><br />
      </branded-dialog-header>
      <div class="list-outline">
        <div class="collection-list-box">
          <div
            v-for="(collection, index) in collections"
            :key="collection.id"
            :class="{
              collectionListItem: true,
              active: index === collectionIndex,
              clickable: true,
            }"
            @click="selectCollection($event, index)"
          >
            <div class="collection-title">
              <HandOutline
                v-if="
                  collection.plan.type !== null &&
                  collection.plan.type === 'donation'
                "
                class="collection-hand-logo"
              />
              <Outline v-else class="collection-bag-logo" />
              <div>
                {{ collection.plan | extractDisplayPurpose }}
                <template v-if="collection.plan.date">
                  <br /><span style="font-style: italic; font-size: 0.85em"
                    >{{ collection.plan.date.name }},
                    {{
                      formatDate(collection.plan.date.day, "D. MMM YYYY")
                    }}</span
                  >
                </template>
              </div>
              <div
                v-if="
                  collection.plan.meta
                    ? collection.plan.meta.description
                      ? collection.plan.meta.description.text
                        ? true
                        : false
                      : false
                    : false
                "
                class="info-icon"
                @click.stop="openDetailDescription(collection)"
              >
                <span
                  style="
                    font-family: Times New Roman;
                    font-weight: bold;
                    font-size: 1.1em;
                    line-height: 1.4em;
                  "
                  >i</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-dialog>
    <app-dialog
      ref="collectionexpired"
      :is-open="isCollectionExpiredDialogOpen"
      @dialog-closed="isCollectionExpiredDialogOpen = false"
    >
      <div class="mt-1">
        <p>
          Der direkt verlinkte Spendenzweck ist leider nicht aktiv. Sie können
          gerne für eine andere Sammlung geben.
        </p>
        <v-btn @click="isCollectionExpiredDialogOpen = false"> OK </v-btn>
      </div>
    </app-dialog>
    <app-dialog
      ref="detailDescription"
      :is-open="isDetailDescriptionDialogOpen"
      @dialog-closed="isDetailDescriptionDialogOpen = false"
    >
      <div
        v-if="
          detailCollection &&
          detailCollection.plan &&
          detailCollection.plan.meta.description.text
        "
        style="text-align: left"
      >
        <h4 style="display: flex">
          <HandOutline
            v-if="
              detailCollection.plan.type !== null &&
              detailCollection.plan.type === 'donation'
            "
            class="collection-hand-logo-headline"
          />
          <Outline v-else class="collection-bag-logo-headline" />
          <span class="detail-collection-title">{{
            detailCollection.plan.display.default
          }}</span>
        </h4>
        <p v-if="detailCollection.plan.start" style="font-size: small">
          Kollektenzweck vom {{ momentize(detailCollection.plan.start) }}
          {{
            detailCollection.plan.end
              ? "bis zum " + momentize(detailCollection.plan.end)
              : "ohne Zeitbegrenzung"
          }}
        </p>
        <div class="collection-list-box">
          <v-card class="collection-description" outlined>
            <div class="pa-2">
              <p>{{ detailCollection.plan.meta.description.text }}</p>
              <p
                v-if="
                  detailCollection.plan.meta.external_fields &&
                  detailCollection.plan.meta.external_fields.www
                "
              >
                <v-icon color="grey" small>
                  {{ mdiWeb }}
                </v-icon>
                <a
                  :href="detailCollection.plan.meta.external_fields.www"
                  target="_blank"
                >
                  {{ detailCollection.plan.meta.external_fields.www }}
                </a>
              </p>
            </div>
          </v-card>
        </div>
        <p class="text-center">
          {{ currentOrganization ? currentOrganization.name : "" }}
        </p>
      </div>
    </app-dialog>
    <div v-show="noCollectionPlanAlert" class="alert">
      <span class="closebtn" @click="noCollectionPlanAlert = false"
        >&times;</span
      >
      Diese Organisation hat noch keinen Sammlungszweck hinterlegt, deshalb
      können Sie momentan nicht spenden.
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component"
import "@/styles/slider.styl"
import moment from "moment"
import { mapActions, mapGetters, mapState } from "vuex"
import {
  DECREASE_COLLECTION_INDEX,
  INCREASE_COLLECTION_INDEX,
  SET_COLLECTION_INDEX,
} from "@/store/action-types"
import {
  extractDisplayPurpose,
  splitPurpose,
} from "@/jscommon/src/lib/regex-tools"
import BrandedDialogHeader from "@/components/BrandedDialogHeader"

import Outline from "@/components/icon/KollekteOutline"
import HandOutline from "@/components/icon/HandOutline"
import DefaultCorner from "@/components/icon/DefaultCorner"
import { pick } from "lodash"

import isModeEmbedMixin from "@/mixins/isModeEmbedMixin"
import { mdiWeb } from "@mdi/js"

import AppDialog from "@/jscommon/src/components/AppDialog.vue"

export default {
  name: "Klingelbeutel",
  filters: {
    extractDisplayPurpose,
  },
  components: {
    VueSlider,
    BrandedDialogHeader,
    Outline,
    HandOutline,
    DefaultCorner,
    AppDialog,
  },
  mixins: [isModeEmbedMixin],
  data () {
    return {
      value: 5,
      customValue: 200,
      payWithoutParish: false,
      purposeFontSize: null,
      noCollectionPlanAlert: false,
      detailCollection: null,
      mdiWeb,
      isCollectionDialogOpen: false,
      isCollectionExpiredDialogOpen: false,
      isDetailDescriptionDialogOpen: false,
    }
  },
  computed: {
    ...mapState("display", ["parishId", "collectionIndex"]),
    ...mapGetters("display", [
      "ready",
      "collections",
      "parishName",
      "currentCollection",
      "currentOrganization",
    ]),
    ...mapGetters("data", ["organizationSettingsByUrl"]),
    hash () {
      return this.$route.hash
        ? this.$route.hash.startsWith("#")
          ? this.$route.hash.substring(1)
          : null
        : null
    },
    collectionDisplay () {
      return splitPurpose(extractDisplayPurpose(this.currentCollection?.plan))
    },
    hideParishSelector () {
      return this.modeEmbed && this.$route.params?.parishId
    },
    collectionTypeForDisplay () {
      return (this.currentCollection?.plan?.type ?? "collection") ===
        "collection"
        ? "Kollekte"
        : "Spende"
    },
    collectionHash: function () {
      /* Returns the collection id in the hash if and only if collections have been loaded */
      if (!this.collections) {
        return null
      }
      // Also require collectionsexpired to be mounted
      if (!this.$refs.collectionexpired) {
        return null
      }
      const hash = this.$router.currentRoute?.hash ?? ""
      if (hash.startsWith("#c/")) {
        return hash.slice(3)
      }
      return null
    },
    settings () {
      return (
        this.organizationSettingsByUrl[this.currentOrganization?.url] ??
        {} ??
        {}
      )
    },
    kollekteAppSettings () {
      return this.settings?.WA ?? {}
    },
    primaryColor () {
      return this.kollekteAppSettings?.primary_color ?? null
    },
    inputInitial () {
      return this.kollekteAppSettings?.input?.initial ?? 5
    },
    inputMax () {
      return this.kollekteAppSettings?.input?.max ?? 999
    },
  },

  watch: {
    collectionHash: {
      immediate: true,
      handler: function (newVal) {
        if (newVal !== null) {
          let found = null
          for (const [idx, col] of this.collections.entries()) {
            if (col.plan.id === newVal) {
              found = idx
              break
            }
          }
          if (found != null) {
            this.setSingleCollection(true)
            this.setCollectionIndex(found)
            this.$router.replace({ hash: null })
          } else {
            this.setSingleCollection(false)
            this.isCollectionExpiredDialogOpen = true
          }
        }
      },
    },
    purposeFontSize: {
      immediate: true,
      handler: function (newVal) {
        if (newVal !== null) {
          this.$nextTick(function () {
            if (document.getElementById("purposeSpan") === null) {
            } else {
              var height = document.getElementById("purposeSpan").offsetHeight
              if (
                this.purposeFontSize >= 10 &&
                height > (this.collectionDisplay.preposition === "" ? 75 : 56)
              ) {
                this.purposeFontSize = this.purposeFontSize - 1
              }
            }
          })
        }
      },
    },
    collectionDisplay: {
      immediate: true,
      handler: function (newVal) {
        this.$nextTick(function () {
          this.purposeFontSize =
            this.purposeFontSize === null || this.purposeFontSize !== 70
              ? 70
              : 71
        })
      },
    },
    primaryColor: {
      immediate: true,
      handler (newVal) {
        const setVal = newVal || "723288"

        this.$store.commit("setTheme", setVal.replace("#", ""))
        this.refreshColor()
      },
    },
    inputInitial: {
      immediate: true,
      handler (newVal) {
        this.value = newVal
      },
    },
  },
  mounted () {
    this.$store.watch(
      (state) => state.display.parishId,
      () => {
        if (this.payWithoutParish) {
          this.payWithoutParish = false
          this.startPayment()
        }
      }
    )
    const sliderElement = document.getElementById("value-slider")
    sliderElement.addEventListener("wheel", this.handleScroll, false)
    this.refreshColor()
  },
  methods: {
    formatDate: function (date, format) {
      moment.locale("de")
      return date === null || format === null
        ? null
        : moment(date).format(format)
    },
    momentize (momDate) {
      return moment(momDate).locale("de").format("L")
    },
    setSingleCollection (value) {
      this.$store.commit("setSingleCollection", value)
    },
    moveToPreviousCollection: function () {
      this.selectPreviousCollection()
    },
    moveToNextCollection: function () {
      this.selectNextCollection()
    },
    fixNewlines: function (value) {
      return value.replace(/(?:\r\n|\r|\n)/g, "<br>")
    },
    escapeHtml: function (value) {
      const div = document.createElement("div")
      div.innerText = value
      return div.innerHTML
    },
    startPayment: function () {
      if (this.parishId) {
        if (this.collections[this.collectionIndex]) {
          const payValue = Math.round(
            Math.min(
              Math.max(this.value === 200 ? this.customValue : this.value, 1),
              this.inputMax
            )
          )
          const paymentInput = {
            organization: this.currentOrganization,
            collection: this.currentCollection,
            amount: payValue,
            settings: pick(
              this.organizationSettingsByUrl[this.currentOrganization.url] ??
                {},
              ["merchantMemo", "privacyLink", "features"]
            ),
            paymentInformation:
              this.$store.state.data.paymentInformationForUrl[
                this.currentOrganization.url
              ] ?? [],
          }
          this.$emit("start-payment", paymentInput)
        } else {
          this.noCollectionPlanAlert = true
        }
      } else {
        this.payWithoutParish = true
        this.selectParish()
      }
    },
    selectParish: function () {
      if (!this.$store.state.singleCollection) {
        this.$emit("change-parish")
      }
    },
    startSelectCollection: function () {
      if (this.ready && !this.$store.state.singleCollection) {
        this.isCollectionDialogOpen = true
      }
    },
    selectCustomValue: function (event) {
      if (this.value !== 200) {
        this.customValue = this.value
      }
      this.value = 200
      this.$nextTick(() => {
        this.$refs.customAmount.select()
        this.$refs.customAmount.focus()
      })
    },
    handleScroll: function (event) {
      const newVal = Math.round(this.value + event.deltaY / 8)
      this.value = Math.max(1, Math.min(200, newVal))
    },
    selectCollection: function (event, index) {
      this.$store.dispatch("display/" + SET_COLLECTION_INDEX, index)
      this.isCollectionDialogOpen = false
    },
    ...mapActions("display", {
      selectPreviousCollection: DECREASE_COLLECTION_INDEX,
      selectNextCollection: INCREASE_COLLECTION_INDEX,
      setCollectionIndex: SET_COLLECTION_INDEX,
    }),
    openDetailDescription (col) {
      this.detailCollection = col
      this.isDetailDescriptionDialogOpen = true
    },
    refreshColor () {
      if (document.getElementById("kb-main")) {
        document
          .getElementById("kb-main")
          .style.setProperty(
            "--kollekte-primary-color",
            this.$store.getters.currentTheme.color
          )
        const bgImg = this.$store.getters.currentTheme.backgroundImage
        document
          .getElementById("kb-main")
          .style.setProperty("--kollekte-background-image", `url(${bgImg})`)
        document
          .getElementById("home")
          .style.setProperty(
            "--kollekteapp-primary-color",
            this.$store.getters.currentTheme.color
          )
        document
          .querySelector(":root")
          .style.setProperty(
            "--kollekteapp-primary-color",
            this.$store.getters.currentTheme.color
          )
        document
          .querySelector(":root")
          .style.setProperty(
            "--kollekte-primary-color",
            this.$store.getters.currentTheme.color
          )
        document
          .getElementById("kollekte.app")
          .style.setProperty(
            "--logo-color",
            this.$store.getters.currentTheme.color === "#3E3E3E"
              ? "#723288"
              : this.$store.getters.currentTheme.color
          )
      }
      // Note: Unmagify the color value
      this.$vuetify.theme.themes.light.primary = JSON.parse(
        JSON.stringify(this.$store.getters.currentTheme.color)
      )
    },
  },
}
</script>

<style scoped lang="stylus">
@import '../styles/main'
#kb-main
  --kollekte-primary-color: '#327359'
  ::-moz-selection
    background: var(--kollekte-primary-color)
  ::selection
    background: var(--kollekte-primary-color)
  a
    color: var(--kollekte-primary-color)

  --kollekte-background-image: url('../assets/theme/klingelbeutel_on_background_violet.jpg')
  background: var(--kollekte-background-image) center -10em / 70em no-repeat, url('../assets/theme/background.jpg') center -10em / 140em

.main
  // --kollekte-primary-color: $clr-primary
  width: 100vw
  height: 100vh /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) /* Set in App.vue */
  display: flex
  flex-direction: row
  overflow: hidden
  justify-content: center
  /* FIXME The multiple background trick doesn't work in IE */

  .clickable
    cursor: pointer

  .modal-container
    position: absolute
    display: block
    margin-left: auto
    margin-right: auto
    width: 400px
    background: '#327359'
    /* FIXME The multiple background trick doesn't work in IE */
    background: url('../assets/theme/klingelbeutel_on_background_violet.jpg') center -10em / 70em no-repeat, url('../assets/theme/background.jpg') center -10em / 140em

  .logo-layer
    width: 100vw
    height: 100vh /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100) /* Set in App.vue */
    max-width: 50em
    max-height: 50em
    position: fixed
    display: flex
    flex-direction: column
    justify-content: space-between
    pointer-events: none

    .top-row, .bottom-row
      display: flex
      flex-direction: row
      justify-content: space-between

    #branding_logo
      max-width: "min(12em, 40vw)" % null /* https://stackoverflow.com/a/63380746 */
      max-height: 4.5em
      padding: 0.5em
      pointer-events: auto

  .controls
    margin-top: 5em
    max-width: 25em
    max-height: 35em

    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between

    .control-row
      display: flex
      flex-direction: column
      flex-grow: 1

    .display-row
      flex-grow: 0

    .parish-row
      flex-grow: 2
      justify-content: flex-start

    .calendar-row
      cursor: default
      flex-grow: 0
      padding-top: 0.7em
      height: 0.5em
      font-style: italic
      color: #82795c

    .slider-row
      justify-content: flex-start
      padding-top: 1.5em
      flex-shrink: 0
      flex-grow: 1.3

    .paybutton-row
      justify-content: flex-end

    .display-container
      margin-top: 0.2em
      height: 9.5em
      width: 20.5em
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center

      .display
        width: 16.2em
        overflow: hidden
        color: #eee
        padding: 0
        align-self: flex-start
        max-height: 9.5em
        line-height: 1.2

        & > *
          width: 100%
          text-align: center
          padding: 0
          padding-bottom: 0.1rem

        .amount
          font-size: 3em
          width: 100%

        .custom-amount
          margin: 0
          padding: 0
          display: inline-block

          input
            width: 3em
            font-size: 0.9em
            text-align: right
            border: 0
            padding: 0
            margin: 0
            background-color: white
            color: black

        .purpose
          font-size: 1.5em
          hyphens: auto

        .info-icon
          margin-top: 0.5em
          text-align: center
          color: black
          line-height: 1.5em
          height: 1.6em
          width: 1.6em
          border-radius: 0.8em
          background: white
          cursor: pointer

      .arrowDiv
        width: 0
        height: 0
        margin-top: 45px
        filter: drop-shadow(3px 7px 8px rgba(0, 0, 0, 0.3))

      .arrowLeft
        font-size: 1.2em
        text-align: center
        .arrowDiv
          border-right: 15px solid var(--kollekte-primary-color)
          border-top: 40px solid transparent
          border-bottom: 40px solid transparent

      .arrowRight
        font-size: 1.2em
        text-align: center
        .arrowDiv
          border-left: 15px solid var(--kollekte-primary-color)
          border-top: 40px solid transparent
          border-bottom: 40px solid transparent

    .display-dots
      text-align: center
      min-height: 1.25em

    .slider
      max-width: 22em
      width: 90vw

    .moreLabel
      max-width: 22em
      width: 90vw
      text-align: right
      color: var(--kollekte-primary-color)

    .parish
      padding: 0.5em
      max-width: 15em;
      font-size: 1.2em
      background-color: #C8BB8D
      color: #82795c
      text-align: center

    .paybutton-container
      text-align: center
      padding-bottom: 1em

    .start-payment
      box-shadow: 3px 7px 8px rgba(0, 0, 0, 0.2);
      color: rgba(255, 255, 255, 0.8)
      background: var(--kollekte-primary-color)
      padding-top: 0.7em
      padding-bottom: 0.7em
      padding-left: 1em
      padding-right: 1em
      border-radius: 0.5em
      font-weight: 550
      text-transform: uppercase
    .start-payment:hover
      box-shadow: 1px 4px 5px rgba(0, 0, 0, 0.2);

  .collectionListItem
    display: block
    border-bottom: 1px solid rgba(0,0,0,0.1)
    text-align: left
    font-size: small
    color: var(--kollekte-primary-color)
    padding: calc(1px + 1em)
    vertical-align: middle
    line-height: 1.8em
  .collectionListItem.active
    border: 2px solid var(--kollekte-primary-color)
    padding: 1em
    padding-right: calc(1em - 1px)

  .collection-hand-logo
    min-width: 2.8em
    padding-right: 0.4em
    vertical-align: middle
  .collection-bag-logo
    min-width: 2.4em
    padding-left: 0.3em
    padding-right: 0.5em
    vertical-align: middle

  .collection-hand-logo-headline
    min-width: 2.8em
    height: 2em
    padding-right: 0.4em
    vertical-align: middle
  .collection-bag-logo-headline
    min-width: 2.4em
    height: 2em
    padding-left: 0.3em
    padding-right: 0.5em
    vertical-align: middle

  .collection-title
    line-height: 1.8em
    text-align: left
    font-size: small
    width: 100%
    display:flex

    .info-icon
      margin-right: 0
      margin-left: auto
      margin-top: auto
      margin-bottom: auto
      text-align: center
      color: white
      line-height: 1.5em
      height: 1.6em
      width: 1.7em
      border-radius: 0.8em
      background: var(--kollekte-primary-color)
      cursor: pointer

  .collection-list-box
    position: relative
    display: block
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3)
    background: rgba(255,255,255,0.8)
    overflow-y: auto
    text-align: center

  .alert
    position: absolute
    bottom: 1em
    padding: 20px
    background-color: var(--kollekte-primary-color)
    color: white
    margin-bottom: 15px

  .closebtn
    margin-left: 15px
    color: white
    font-weight: bold
    float: right
    font-size: 22px
    line-height: 20px
    cursor: pointer
    transition: 0.3s

  .closebtn:hover
    color: black

  .close-modal-button
    float: right
    font-size: large
    font-weight: bold
    text-align: center
    width: 2em
    height: 2em
    line-height: 2em
    border-radius: 1em
    color: white
    margin-top: -1em
    margin-right: -0.5em
    color: black
    cursor: pointer
  .close-modal-button:hover
    background: var(--kollekte-primary-color)
    color: white

.collection-description
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)
</style>
