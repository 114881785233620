import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"

import config from "@/config"
import moment from "moment"
import display from "./display"
import payment from "@/jscommon/src/store/payment"
import data from "./data"
import brands from "@/brands"
import {
  SET_BRANDING,
  SET_PARISH_AND_COLLECTION,
  UPDATE_TIME,
  SET_IS_SINGLE_COLLECITON,
  SET_THEME,
} from "@/store/mutation-types"

Vue.use(Vuex)

let vuexLocal = null
if (config.features?.saveParish ?? false) {
  vuexLocal = new VuexPersistence({
    key: "kollekte.app-state",
    storage: window.localStorage,
    reducer: (state) =>
      config.features.saveParish
        ? { "display.parishId": state.display.parishId }
        : {}, // only save selected parishId
    filter: (mutation) =>
      config.features.saveParish && mutation.type === SET_PARISH_AND_COLLECTION,
  })
}

export default new Vuex.Store({
  state () {
    return {
      now: moment(),
      label: "kollekte.app",
      branding: "_default",
      singleCollection: false,
      colorThemes: {
        723288: {
          color: "#723288",
          backgroundImage: require("../assets/theme/klingelbeutel_on_background_violet.jpg"),
        },
        327359: {
          color: "#327359",
          backgroundImage: require("../assets/theme/klingelbeutel_on_background_green.jpg"),
        },
        "89313D": {
          color: "#89313D",
          backgroundImage: require("../assets/theme/klingelbeutel_on_background_red.jpg"),
        },
        "3E3E3E": {
          color: "#3E3E3E",
          backgroundImage: require("../assets/theme/klingelbeutel_on_background_black.jpg"),
        },
      },
      currentColorTheme: null,
    }
  },
  modules: {
    display,
    payment,
    data,
  },
  plugins: vuexLocal !== null ? [vuexLocal.plugin] : [],
  mutations: {
    [UPDATE_TIME] (state) {
      state.now = moment()
    },
    [SET_BRANDING] (state, value) {
      state.branding = value
    },
    [SET_IS_SINGLE_COLLECITON] (state, newValue) {
      this.state.singleCollection = newValue
    },
    [SET_THEME] (state, newValue) {
      this.state.currentColorTheme =
        state.colorThemes[newValue] || state.colorThemes["723288"]
    },
  },
  actions: {
    startTimer ({ commit }) {
      function doUpdate () {
        commit(UPDATE_TIME)
        // Execute this again at ~1 second past the next full minute
        setTimeout(doUpdate, 60000 - (moment() % 60000) + 1000)
      }
      doUpdate()
    },
  },
  getters: {
    currentBrand: function (state) {
      return brands[state.branding ?? "default"]
    },
    currentBrandShortname: function (state) {
      return state.branding ?? "default"
    },
    currentTheme: function (state) {
      if (state.currentColorTheme === null) {
        state.currentColorTheme = state.colorThemes["723288"]
      }
      return state.currentColorTheme
    },
  },
})
