import Vue from "vue"

import Buntpapier from "buntpapier"
import Vuelidate from "vuelidate"
import SweetModal from "sweet-modal-vue/src/plugin"
import VueMatomo from "vue-matomo"
import VueCurrencyFilter from "vue-currency-filter"
import vueHeadful from "vue-headful"

import App from "./App.vue"
import "./registerServiceWorker"
import config from "@/config"
import router from "@/router"
import store from "@/store"
import { sync } from "vuex-router-sync"
import { shortenParish } from "./jscommon/src/lib/regex-tools"

import "roboto-fontface/css/roboto/sass/roboto-fontface-regular.scss"
import "roboto-fontface/css/roboto/sass/roboto-fontface-medium.scss"
import "@/styles/main.styl"

import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import vuetify from "./plugins/vuetify"

if (config.sentry.dsn) {
  Sentry.init({
    Vue,
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    release: config.sentry.release,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: config.deployment === "production" ? 0 : 1.0,
    logErrors: true,
  })
}

Vue.config.productionTip = false
Vue.use(Buntpapier)
Vue.use(Vuelidate)
Vue.use(SweetModal)
Vue.use(VueCurrencyFilter, {
  symbol: "€",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "back",
  symbolSpacing: true,
})

Vue.filter("shortenParish", shortenParish)

Vue.component("vue-headful", vueHeadful)

if (config.features.matomo) {
  Vue.use(VueMatomo, {
    host: process.env.VUE_APP_MATOMO_HOST,
    siteId: process.env.VUE_APP_MATOMO_ID,
    trackerFileName: "matomo",
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: [],
  })
}

sync(store, router)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
