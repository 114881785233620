<template>
  <v-dialog
    v-model="isOpen"
    :max-width="maxWidth"
    :persistent="persistent"
    :content-class="hideOverflowX ? 'overflow-x-hidden' : ''"
    @click:outside="persistent ? null : closeDialog()"
  >
    <v-card class="pa-6 dialog-content theme-transparent">
      <slot name="top">
        <v-row v-if="showCloseButton" justify="end">
          <v-btn
            icon
            small
            color="primary"
            :class="{ 'close-btn-primary': isHoveringOnCloseBtn }"
            @click="closeDialog"
            @mouseover="isHoveringOnCloseBtn = true"
            @mouseleave="isHoveringOnCloseBtn = false"
          >
            <v-icon small color="black">
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-row>
      </slot>
      <slot />
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js"
export default {
  name: "AppDialog",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxWidth: {
      type: String,
      required: false,
      default: "600px",
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideOverflowX: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      isHoveringOnCloseBtn: false,
      mdiClose,
    }
  },
  methods: {
    closeDialog () {
      this.$emit("dialog-closed")
    },
  },
}
</script>

<style lang="stylus" scoped>
.close-btn-primary {
  background-color: var(--spendeapp-primary-color)
}
</style>
