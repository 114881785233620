<template>
  <!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    v-if="isDefault"
    id="svg43"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 89.6 93.5"
    style="enable-background: new 0 0 89.6 93.5"
    xml:space="preserve"
  >
    <sodipodi:namedview
      id="namedview45"
      bordercolor="#666666"
      borderopacity="1"
      fit-margin-bottom="0"
      fit-margin-left="0"
      fit-margin-right="0"
      fit-margin-top="0"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:current-layer="svg43"
      inkscape:cx="374.1733"
      inkscape:cy="62.361668"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-height="1017"
      inkscape:window-maximized="1"
      inkscape:window-width="1920"
      inkscape:window-x="1912"
      inkscape:window-y="-8"
      inkscape:zoom="2.22376"
      objecttolerance="10"
      pagecolor="#ffffff"
      showgrid="false"
    />
    <rect y="0" class="st0x" width="89.6" height="93.5" />
    <path
      class="st1"
      d="M11.7,56.7C10.2,56.7,9,55.5,9,54c0-1.2,0.7-2.2,1.8-2.6l60.9-21.8c1.4-0.5,3,0.2,3.5,1.6l0,0
        c0.5,1.4-0.2,3-1.6,3.5l-61,21.8l-0.4,0.2H11.7z"
    />
    <path
      class="st1x"
      d="M75.7,41.6c0-1.6-1.4-2.9-3-2.8c-0.3,0-0.6,0.1-0.9,0.2l-23.5,8.4c-1.5,0.5-2.3,2.2-1.8,3.7
        c0.9,2.7,2,5.3,3.4,7.8c1.7,3.1,2.7,4.5,4.4,6.2c0.6,0.6,3.9,3.6,8.8,3.6c0.6,0,1.2,0,1.8-0.1c1.9-0.3,3.7-1.1,5.1-2.6
        c1.4-1.3,2.5-3.2,3.4-5.4C75.2,56.1,76,49.7,75.7,41.6z"
    />
  </svg>

  <img v-else-if="brand" id="branding_logo" :src="brand.small" />
</template>
<script>
export default {
  name: "DefaultCorner",
  props: {
    brand: {
      type: Object,
      default: () => {},
    },
    isDefault: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style>
.st0x {
  fill: var(--kollekteapp-primary-color);
}
.st1x {
  fill: #ffffff;
}
</style>
