const apiBase =
  (dynamicConfig?.API_BASE_URL ?? process.env?.VUE_APP_API_BASE_URL) ||
  "http://localhost:8000"
const deployment =
  dynamicConfig.DEPLOYMENT_TYPE ??
  process.env?.VUE_APP_DEPLOYMENT_TYPE ??
  process.env.NODE_ENV

const config = {
  backend: {
    rest: `${apiBase}/rest/public/v2/`,
  },
  features: ["serviceWorker", "saveParish", "matomo", "geoLocation"].reduce(
    (obj, x) => {
      obj[x] = process.env.VUE_APP_FEATURES.split(" ").includes(x)
      return obj
    },
    {}
  ),
  deployment,
  sentry: {
    dsn: dynamicConfig.SENTRY_DSN ?? (process?.env?.VUE_APP_SENTRY_DSN || ""),
    environment: process?.env?.VUE_APP_SENTRY_ENVIRONMENT || deployment,
    release:
      process?.env?.VUE_APP_SENTRY_RELEASE ||
      (process?.env?.PACKAGE_VERSION ?? null),
  },
  payment: {
    widgetBase:
      dynamicConfig?.PAYMENT_WIDGET_BASE ??
      process.env.VUE_APP_PAYMENT_WIDGET_BASE,
    enabled: process.env.VUE_APP_PAYMENT_ENABLED.split(" "),
  },
  kollekteApp: {
    url:
      dynamicConfig.KOLLEKTEAPP_BASE_URL ??
      process.env?.VUE_APP_KOLLEKTEAPP_BASE_URL ??
      "http://localhost:8080",
  },
}
export default config
