export function dateDisplay (v) {
  return (
    v &&
    new Date(v).toLocaleString(undefined, {
      day: "numeric",
      year: "numeric",
      month: "2-digit",
    })
  )
}
