import { helpers, email as _email } from "vuelidate/lib/validators"
import tlds from "tlds"
import { electronicFormatIBAN, isValidIBAN } from "ibantools"
import { len } from "vuelidate/lib/validators/common"

const _tldRegex = new RegExp("(?:@|\\.)(?:" + tlds.join("|") + ")$", "i")

const _validTld = helpers.regex("validTld", _tldRegex)

export const required = (message) => (value) => !!value || message
export const email = (message) => (value) => _email(value) || message
export const validTld = (message) => (value) => _validTld(value) || message
export const checkIban = (message) => (value) => {
  const iban = electronicFormatIBAN(value)
  return !!(value && isValidIBAN(iban)) || message
}
export const minLength = (length, message) => (value) =>
  !!(value && len(value) >= length) || message
