import Vue from "vue"
import Router from "vue-router"

import Home from "./views/Home.vue"
import ToS from "./views/ToS.vue"
import DataPrivacy from "./views/DataPrivacy.vue"

import store from "./store"

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/p/:parishId",
      name: "parish-selected",
      component: Home,
    },
    {
      path: "/s/:orgPath*",
      name: "org-search",
      component: Home,
    },
    {
      path: "/p/:parishId/done/:onlinePaymentId?",
      name: "done",
      component: Home,
    },
    {
      path: "/datenschutz",
      name: "data-privacy",
      component: DataPrivacy,
    },
    {
      path: "/agb",
      name: "agbs",
      component: ToS,
    },
  ],
})
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next({ name: "home" })
  } else if (to.name === "home" && store.state.selectedParishId) {
    next({
      name: "parish-selected",
      params: { parishId: store.state.selectedParishId },
    })
  } else next()
})

export function getDonePath ({ organizationId, onlinePaymentId }) {
  return router.resolve({
    name: "done",
    params: { parishId: organizationId, onlinePaymentId },
  })
}

export default router
