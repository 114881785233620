<template>
  <!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    v-if="defaultLogo"
    id="kollekte.app"
    class="label_logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 312.36 55.32"
  >
    <path
      class="cls-1x"
      d="M18.18,44l-8-16.56-5.46,7.5V44H.18V2.78L4.74.26v28.8L6,27.14,16.32,12.5h5L13.08,23.66,23,44Z"
      transform="translate(-0.18 -0.26)"
    />
    <path
      class="cls-1x"
      d="M54.72,28a21.51,21.51,0,0,1-1.08,7.11,15.63,15.63,0,0,1-3,5.22,12.11,12.11,0,0,1-4.39,3.21,14.2,14.2,0,0,1-10.79,0,11.54,11.54,0,0,1-4.3-3.12,15.28,15.28,0,0,1-2.86-5.13,21.8,21.8,0,0,1-1.05-7.08,21.24,21.24,0,0,1,1.08-7.08,15.09,15.09,0,0,1,2.95-5.13,12.34,12.34,0,0,1,4.39-3.15,13.61,13.61,0,0,1,5.41-1.08,14.08,14.08,0,0,1,5.38,1,11.39,11.39,0,0,1,4.3,3.06A15,15,0,0,1,53.67,21,21.53,21.53,0,0,1,54.72,28Zm-4.56.12q0-6.27-2.52-9.37A8.16,8.16,0,0,0,41,15.68a8.07,8.07,0,0,0-6.63,3.11q-2.49,3.11-2.49,9.37t2.49,9.47A8,8,0,0,0,41,40.82a8.06,8.06,0,0,0,6.66-3.19Q50.16,34.44,50.16,28.16Z"
      transform="translate(-0.18 -0.26)"
    />
    <path
      class="cls-1x"
      d="M63.24,44V2.78L67.8.26V44Z"
      transform="translate(-0.18 -0.26)"
    />
    <path
      class="cls-1x"
      d="M78.3,44V2.78L82.86.26V44Z"
      transform="translate(-0.18 -0.26)"
    />
    <path
      class="cls-1x"
      d="M117,27.32q0,.84,0,1.38a6.68,6.68,0,0,1-.15,1.08H95.94a14.37,14.37,0,0,0,2.58,8.09,7.39,7.39,0,0,0,6.18,3,12.22,12.22,0,0,0,4.65-.78,23.73,23.73,0,0,0,4.05-2.22l2.1,3.24c-1,.59-1.86,1.11-2.7,1.57a17.2,17.2,0,0,1-2.58,1.12,18.55,18.55,0,0,1-2.76.68,19.89,19.89,0,0,1-3.24.23,12.8,12.8,0,0,1-4.44-.84A10.82,10.82,0,0,1,95.65,41a15.32,15.32,0,0,1-3.06-5.13,22,22,0,0,1-1.21-7.8,21.58,21.58,0,0,1,1-6.78,15.32,15.32,0,0,1,2.72-5.1A12.08,12.08,0,0,1,99.19,13a11.77,11.77,0,0,1,5.18-1.14A12.37,12.37,0,0,1,109.79,13a11.18,11.18,0,0,1,4,3.21,14.33,14.33,0,0,1,2.42,4.92A22.65,22.65,0,0,1,117,27.32Zm-4.14-1.2a14.6,14.6,0,0,0-2.33-7.54,7,7,0,0,0-6.16-2.9,7.31,7.31,0,0,0-5.77,2.57c-1.5,1.71-2.38,4.33-2.66,7.87Z"
      transform="translate(-0.18 -0.26)"
    />
    <path
      class="cls-1x"
      d="M143.46,44l-8-16.56L130,34.94V44h-4.56V2.78L130,.26v28.8l1.26-1.92L141.6,12.5h5l-8.28,11.16,10,20.34Z"
      transform="translate(-0.18 -0.26)"
    />
    <path
      class="cls-1x"
      d="M160.8,16.34V36.81a4.73,4.73,0,0,0,.69,2.94,3.12,3.12,0,0,0,2.49.83,11.58,11.58,0,0,0,2.37-.21,9.85,9.85,0,0,0,2.07-.69l-.3,3.9a14.61,14.61,0,0,1-2.67.81,16.22,16.22,0,0,1-3.15.27,6,6,0,0,1-4.51-1.58c-1-1.06-1.55-2.91-1.55-5.54V16.34h-3.9V12.5h3.9V2.78L160.8.26V12.5h7.8v3.84Z"
      transform="translate(-0.18 -0.26)"
    />
    <path
      class="cls-1x"
      d="M200,27.32q0,.84,0,1.38a6.68,6.68,0,0,1-.15,1.08H179a14.37,14.37,0,0,0,2.58,8.09,7.39,7.39,0,0,0,6.18,3,12.22,12.22,0,0,0,4.65-.78,23.73,23.73,0,0,0,4.05-2.22l2.1,3.24c-1,.59-1.86,1.11-2.7,1.57a17.2,17.2,0,0,1-2.58,1.12,18.55,18.55,0,0,1-2.76.68,19.89,19.89,0,0,1-3.24.23,12.8,12.8,0,0,1-4.44-.84A10.82,10.82,0,0,1,178.69,41a15.32,15.32,0,0,1-3.06-5.13,22,22,0,0,1-1.21-7.8,21.58,21.58,0,0,1,1-6.78,15.32,15.32,0,0,1,2.72-5.1,12.08,12.08,0,0,1,4.1-3.24,11.77,11.77,0,0,1,5.18-1.14A12.29,12.29,0,0,1,192.82,13a11.12,11.12,0,0,1,4,3.21,14.33,14.33,0,0,1,2.42,4.92A22.65,22.65,0,0,1,200,27.32Zm-4.14-1.2a14.6,14.6,0,0,0-2.33-7.54,7,7,0,0,0-6.16-2.9,7.31,7.31,0,0,0-5.77,2.57c-1.5,1.71-2.38,4.33-2.66,7.87Z"
      transform="translate(-0.18 -0.26)"
    />
    <path
      d="M213.06,40.82a3.84,3.84,0,0,1-7.68,0,3.89,3.89,0,0,1,1.14-2.76,3.64,3.64,0,0,1,5.4,0A3.89,3.89,0,0,1,213.06,40.82Z"
      transform="translate(-0.18 -0.26)"
    />
    <path
      d="M239.46,44V40.16a17,17,0,0,1-4.05,3.15,11.53,11.53,0,0,1-5.79,1.35,13.36,13.36,0,0,1-3.66-.51,9,9,0,0,1-3.24-1.68,8.32,8.32,0,0,1-2.31-3.09,11.64,11.64,0,0,1-.87-4.8,9.59,9.59,0,0,1,1-4.56,9.31,9.31,0,0,1,2.67-3.15,11,11,0,0,1,3.84-1.8,17.91,17.91,0,0,1,4.53-.57A18.28,18.28,0,0,1,236,25a13.75,13.75,0,0,1,3.42,1.29V22.6q0-6.92-6.82-6.92a15.63,15.63,0,0,0-4.57.63,18,18,0,0,0-4,1.77l-1.68-3.68a20.42,20.42,0,0,1,4.8-1.85,22.72,22.72,0,0,1,5.76-.71q5.1,0,8.07,2.37T244,22V44Zm0-14.1a15.23,15.23,0,0,0-3.38-1.32,18.88,18.88,0,0,0-4.69-.48A8.32,8.32,0,0,0,226,29.69,5.57,5.57,0,0,0,224,34.28c0,2.36.58,4.07,1.76,5.13A6.66,6.66,0,0,0,230.43,41a10,10,0,0,0,5.32-1.38,12.54,12.54,0,0,0,3.71-3.36Z"
      transform="translate(-0.18 -0.26)"
    />
    <path
      d="M279,27.74a26.06,26.06,0,0,1-.93,7.35,16.43,16.43,0,0,1-2.54,5.28,10.89,10.89,0,0,1-3.77,3.21,10.15,10.15,0,0,1-4.61,1.08,10.48,10.48,0,0,1-5.08-1.14,13.51,13.51,0,0,1-3.77-3.06v12.6l-4.56,2.52V12.5h4.56l-.06,3.84a17.73,17.73,0,0,1,4-3.15,11.48,11.48,0,0,1,9.93-.42,9.62,9.62,0,0,1,3.6,2.85,14.11,14.11,0,0,1,2.4,5A26.15,26.15,0,0,1,279,27.74Zm-4.44.06q0-6-2.1-9.07a6.69,6.69,0,0,0-5.85-3.05A8.79,8.79,0,0,0,262,16.92a13.78,13.78,0,0,0-3.73,3.35V36.06a11.51,11.51,0,0,0,3.49,3.38,9.16,9.16,0,0,0,5,1.38,6.29,6.29,0,0,0,5.85-3.31Q274.56,34.19,274.56,27.8Z"
      transform="translate(-0.18 -0.26)"
    />
    <path
      d="M312.54,27.74a26.06,26.06,0,0,1-.93,7.35,16.43,16.43,0,0,1-2.54,5.28,10.89,10.89,0,0,1-3.77,3.21,10.18,10.18,0,0,1-4.61,1.08,10.55,10.55,0,0,1-5.09-1.14,13.61,13.61,0,0,1-3.76-3.06v12.6l-4.56,2.52V12.5h4.56l-.06,3.84a17.5,17.5,0,0,1,4-3.15,11.48,11.48,0,0,1,9.93-.42,9.62,9.62,0,0,1,3.6,2.85,14.11,14.11,0,0,1,2.4,5A26.55,26.55,0,0,1,312.54,27.74Zm-4.44.06q0-6-2.1-9.07a6.7,6.7,0,0,0-5.86-3.05,8.78,8.78,0,0,0-4.57,1.24,13.78,13.78,0,0,0-3.73,3.35V36.06a11.51,11.51,0,0,0,3.49,3.38,9.15,9.15,0,0,0,5,1.38,6.31,6.31,0,0,0,5.86-3.31Q308.1,34.19,308.1,27.8Z"
      transform="translate(-0.18 -0.26)"
    />
  </svg>

  <img v-else class="label_logo" :src="labelLogo" alt="kollekte.app" />
</template>
<script>
export default {
  name: "DefaultCorner",
  computed: {
    labelLogo: function () {
      return require("@/assets/label/" + this.$store.state.label + ".svg")
    },
    defaultLogo: function () {
      return this.$store.state.label === "kollekte.app"
    },
  },
}
</script>
<style lang="stylus">
#kollekte.app
  --logo-color: yellow

.cls-1x {
  fill: var(--logo-color);
}
</style>
