<template>
  <div class="brand-chooser">
    <v-item-group v-model="innerValue" mandatory>
      <v-row dense>
        <v-item
          v-for="method in availableMethods"
          :key="method.integration + '/' + method.method"
          v-slot="{ active, toggle }"
          :value="method"
        >
          <v-col
            :sm="method.integration === 'direct' ? 12 : 6"
            cols="12"
            class="pa-1"
            min-width="10em"
            @click="toggle"
          >
            <v-btn
              block
              tile
              :outlined="active"
              :depressed="active"
              height="4.5em"
            >
              <template v-if="method.method === 'sepadd'">
                <v-img
                  src="../assets/payment/sepadd.svg"
                  alt="SEPA-Lastschrift"
                  contain
                  height="4em"
                  eager
                  max-width="12em"
                />
              </template>
              <template v-else-if="method.method === 'creditcard'">
                <v-img
                  src="../assets/payment/Visa_BlueGradient.svg"
                  alt="Visa"
                  contain
                  height="4em"
                  eager
                  max-width="4.2em"
                />
                <v-img
                  src="../assets/payment/mc_hrz_pos.svg"
                  alt="MasterCard"
                  contain
                  height="4em"
                  eager
                  max-width="9.6em"
                />
              </template>
              <template v-else-if="method.method === 'giropay'">
                <v-img
                  src="../assets/payment/giropay.svg"
                  alt="Giropay"
                  contain
                  height="4em"
                  eager
                  max-width="12em"
                />
              </template>
              <template v-else-if="method.method === 'googlepay'">
                <v-img
                  src="../assets/payment/googlepay.svg"
                  alt="Google Pay"
                  contain
                  height="4em"
                  eager
                  max-width="12em"
                />
              </template>
              <template v-else-if="method.method === 'paypal'">
                <v-img
                  src="../assets/payment/paypal.svg"
                  alt="PayPal"
                  contain
                  height="4em"
                  eager
                  max-width="12em"
                />
              </template>
            </v-btn>
          </v-col>
        </v-item>
      </v-row>
    </v-item-group>
    <span class="note">(Sortierung aufsteigend nach Gebühren)</span>
  </div>
</template>
<script>
export default {
  name: "PaymentChooser",
  props: {
    availableMethods: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      innerValue: this.value,
    }
  },
  computed: {
    primaryColor () {
      return this.$vuetify.theme.currentTheme.primary
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        this.innerValue = newValue
      },
    },
    innerValue: {
      handler (newValue) {
        this.$emit("input", newValue)
      },
    },
    primaryColor: {
      immediate: true,
      handler (newValue) {
        if (this.$el) {
          this.$el.style.setProperty("--payment-chooser-primary", newValue)
        }
      },
    },
  },
  mounted () {
    this.$el.style.setProperty("--payment-chooser-primary", this.primaryColor)
  },
}
</script>
<style lang="stylus" scoped>
.brand-chooser
  .note
    font-size: 0.7em
    display: block
    margin-bottom: 0.75em
    width: 100%
    text-align: right

  .v-btn.v-btn--block
    padding: 3px

  .v-btn--outlined
    padding: 0
    border: 3px solid var(--payment-chooser-primary)
    background-color: white
</style>
