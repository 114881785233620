const brands = {
  kkbs: {
    small: require("@/assets/branding/kkbs_corner.svg"),
    corner: require("@/assets/branding/kkbs_corner.svg"),
    long: require("@/assets/branding/kkbs_long.svg"),
  },
  kkts: {
    small: require("@/assets/branding/kkts_short.svg"),
    corner: require("@/assets/branding/kkts_short.svg"),
    long: require("@/assets/branding/kkts_long.svg"),
  },
  kkr: {
    small: require("@/assets/branding/default_corner.svg"),
    corner: require("@/assets/branding/kkr_long.svg"),
    long: require("@/assets/branding/kkr_long.svg"),
  },
  ekbo: {
    small: require("@/assets/branding/ekbo_short.svg"),
    corner: require("@/assets/branding/ekbo_short.svg"),
    long: require("@/assets/branding/ekbo_short.svg"),
  },
  stnicolailemgo: {
    small: require("@/assets/branding/stnicolailemgo_logo.svg"),
    corner: require("@/assets/branding/stnicolailemgo_logo.svg"),
    long: null,
  },
  stmarienlemgo: {
    small: require("@/assets/branding/stmarienlemgo_corner.png"),
    corner: require("@/assets/branding/stmarienlemgo_corner.png"),
    long: null,
  },
  bmwlichtenrade: {
    small: require("@/assets/branding/lichtenrade_corner.png"),
    corner: require("@/assets/branding/lichtenrade_corner.png"),
    long: require("@/assets/branding/kkts_long.svg"),
  },
  detmoldlutherisch: {
    small: require("@/assets/branding/detmoldlutherisch_logo.svg"),
    corner: require("@/assets/branding/detmoldlutherisch_logo.svg"),
    long: null,
  },
  lippelutherisch: {
    small: require("@/assets/branding/lippelutherisch_logo.png"),
    corner: require("@/assets/branding/lippelutherisch_logo.png"),
    long: null,
  },
  karow: {
    small: require("@/assets/branding/karow_short.svg"),
    corner: require("@/assets/branding/karow_short.svg"),
    long: null,
  },
}
brands._default = {
  small: require("@/assets/branding/default_corner.svg"),
  corner: require("@/assets/branding/default_corner.svg"),
  long: require("@/assets/branding/default_long.svg"),
}
export default brands
