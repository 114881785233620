<template>
  <div>
    <div
      v-if="state && ![STATE_INIT, STATE_DONE].includes(state)"
      id="payment-container"
      ref="payment-container"
      style="color: rgba(0, 0, 0, 0.54)"
    >
      <h4 v-if="selectedMethod.method === 'sepadd'">SEPA-Lastschrift</h4>
      <h4 v-else-if="selectedMethod.method === 'creditcard'">
        <!-- Kreditkartenzahlung -->
        VISA / Mastercard
      </h4>
      <h4 v-else-if="selectedMethod.method === 'giropay'">
        <!-- GiroPay -->
      </h4>
      <h4 v-else-if="selectedMethod.method === 'googlepay'">GooglePay</h4>
      <h4 v-else-if="selectedMethod.method === 'paypal'">PayPal</h4>
      <v-expand-transition>
        <div v-if="!formReady" style="width: 3em" class="ma-auto mb-4">
          <v-progress-circular
            color="primary"
            :size="70"
            :width="5"
            indeterminate
          />
        </div>
      </v-expand-transition>
    </div>
    <template v-if="state === STATE_INIT">
      <slot name="initiatingpre" />
      <slot name="initiatingpost" />
    </template>
    <slot name="actions" />
    <slot name="footer" :footerInfo="additionalFooterInformation" />
  </div>
</template>
<script>
import { mapState } from "vuex"
import { extractDisplayPurpose } from "../lib/regex-tools"
import config from "@/config"
import {
  UPDATE_INTEGRATION_DATA,
  SET_MANDATE_ID,
  SET_MEMO_TEXT,
} from "../store/payment/mutation-types"
import axios from "axios"
import { getDonePath } from "@/router"
import {
  STATE_CHECK,
  STATE_DONE,
  STATE_EXEC,
  STATE_HANDLE,
  STATE_INIT,
} from "../store/payment/state-types"

export default {
  name: "PaymentFragmentOppwa",
  props: {
    state: {
      type: String,
      required: false,
      default: null,
    },
    appKind: {
      validator: (x) => ["spende.app", "kollekte.app"].includes(x),
      default: "kollekte.app",
    },
    selectedMethod: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      formRendered: false,
      formReady: false,
      STATE_INIT,
      STATE_DONE,
    }
  },
  computed: {
    ...mapState("payment", ["integrationData", "mandateId", "memoText"]),
    ...mapState("payment", {
      paymentInput: "input",
    }),
    brand () {
      if (this.selectedMethod?.integration !== "oppwa") {
        return null
      }
      if (this.selectedMethod?.method === "sepadd") {
        return "DIRECTDEBIT_SEPA"
      } else if (this.selectedMethod?.method === "giropay") {
        return "GIROPAY"
      } else if (this.selectedMethod?.method === "googlepay") {
        return "GOOGLEPAY"
      } else if (this.selectedMethod?.method === "paypal") {
        return "PAYPAL"
      } else if (this.selectedMethod?.method === "creditcard") {
        const enabledBrands = ["VISA", "MASTER"].filter((x) =>
          config.payment.enabled.includes(x)
        )
        return enabledBrands.join(" ")
      }
      return null
    },
    additionalFooterInformation () {
      const merchantMemo = this.paymentInput?.merchantMemo || ""

      if (
        this.mandateId &&
        this.memoText &&
        this.selectedMethod?.method === "sepadd"
      ) {
        return `<br>Die Abbuchung erfolgt in den nächsten Tagen mit der SEPA-Mandats-ID ${this.mandateId} und dem Verwendungszweck „${this.memoText}“.`
      }

      if (this.memoText) {
        return `Dort erscheint „${this.memoText}“ als Verwendungszweck.`
      }

      if (merchantMemo) {
        return `Dort erscheint „${merchantMemo}“ als Verwendungszweck.`
      }

      return 'Dort erscheint das Wort „Spende" und der Name der Organisation als Verwendungszweck.'
    },
  },
  watch: {
    selectedMethod: {
      immediate: true,
      async handler (newValue) {
        if (newValue?.integration === "oppwa") {
          this.$emit("request-additional-fields", new Set([]))
        }
      },
    },
    state: {
      immediate: true,
      async handler (newValue, oldValue) {
        if ([STATE_INIT, null].includes(newValue)) {
          if (this.formRendered) {
            this._intDestroyPaymentForm()
          }
        }

        if (this.selectedMethod?.integration !== "oppwa") {
          return
        }

        if (newValue === STATE_HANDLE) {
          await this.startPaymentOnBackend()
          await this.$nextTick()
          this._intRenderPaymentForm()
        } else if (newValue === STATE_EXEC) {
          // handling -> executing: click oppwa button
          if (oldValue === STATE_HANDLE) {
            document
              .querySelectorAll(
                'form.wpwl-form .wpwl-wrapper-submit button[type="submit"]'
              )
              .forEach((element) => element.click())
          }
        } else if (newValue === STATE_CHECK) {
          // null -> check: check payment status
          await this.completePaymentOnBackend()
        } else if (newValue === STATE_DONE) {
          if (this.formRendered) {
            this._intDestroyPaymentForm()
          }
        }
      },
    },
    "$vuetify.theme.currentTheme.primary": {
      immediate: true,
      handler (newValue) {
        if (this.$el) {
          this.$el.style.setProperty(
            "--gebendigital-jscommon-integration-oppwa-primary-color",
            newValue
          )
        }
      },
    },
  },
  mounted () {
    this.$el.style.setProperty(
      "--gebendigital-jscommon-integration-oppwa-primary-color",
      this.$vuetify.theme.currentTheme.primary
    )
  },
  beforeDestroy () {
    if (this.formRendered) {
      this._intDestroyPaymentForm()
    }
  },
  methods: {
    _intRenderPaymentForm: function () {
      const validateHolder = function () {
        let allowed = true
        document
          .querySelectorAll(".wpwl-control-cardHolder")
          .forEach((holderElement) => {
            const holder = holderElement.value
            if (holder.trim().length < 2) {
              holderElement.classList.add("wpwl-has-error")
              allowed = false
            }
          })
        return allowed
      }
      const paymentBrand = this.brand
      const eType = this.selectedMethod.method
      let billingAddress = null
      const personData = this.$store.state.payment?.personData ?? null
      const mandatoryFields = {
        city: false,
        country: false,
        street1: false,
        postcode: false,
        state: false,
      }
      if (eType === "creditcard") {
        mandatoryFields.city =
          mandatoryFields.country =
          mandatoryFields.street1 =
          mandatoryFields.postcode =
            true
        billingAddress = {
          country: "DE",
        }
      }

      const makeElementsRequired = (lazy) => {
        document
          .querySelectorAll("form.wpwl-form input.wpwl-control")
          .forEach((element) => {
            const name = element.getAttribute("name")
            if (
              name &&
              ((name.startsWith("billing.") &&
                (mandatoryFields[name.slice(8)] ?? false)) ||
                ["card.holder", "customer.email"].includes(name))
            ) {
              const handler = () => {
                element.required = true
                element.removeEventListener("blur", handler)
                element.removeEventListener("input", handler)
              }
              if (lazy) {
                element.addEventListener("blur", handler)
                element.addEventListener("input", handler)
              } else {
                element.required = true
              }
              // element.setCustomValidity('Die Adresse wird zur Abwicklung der Zahlung benötigt')
            }
          })
      }

      const outerThis = this

      window.wpwlOptions = {
        locale: "de-DE",
        brandDetection: true,
        brandDetectionType: "regex",
        labels: {
          submit: "Jetzt geben",
        },
        spinner: {
          color: outerThis.$vuetify.theme.currentTheme.primary,
        },
        cart: {
          items: [
            {
              name: extractDisplayPurpose(
                outerThis.$store.state.payment.input.collection.plan
              ),
              quantity: 1,
              price: outerThis.$store.state.payment.input.amount,
            },
          ],
        },
        mandatoryBillingFields: mandatoryFields,
        ...(billingAddress !== null ? { billingAddress } : {}),
        googlePay: {
          // FIXME These need to provided by server API
          gatewayMerchantId: "8ac7a4c872dae5960172e0d8f22a17d2",
          merchantId: "01234567890123456789",
          merchantName: outerThis.$store.state.payment.input.parishName,
        },
        customParameters: {
          PAYPAL_USE_NEW_VERSION: "Yes",
        },
        onReady: function () {
          document.querySelectorAll(".wpwl-label-billing").forEach((label) => {
            label.textContent =
              eType === "creditcard"
                ? "Adresse des Karteninhabers"
                : "Adresse des Kontoinhabers"
          })
          const stateField = document.getElementsByClassName(
            "wpwl-control-stateSelect"
          )
          if (stateField[0]) {
            stateField[0].parentNode.parentNode.removeChild(
              stateField[0].parentNode
            )
          }
          document
            .querySelectorAll(".wpwl-control-expiry")
            .forEach((expiryElement) => {
              expiryElement.style["text-align"] = "center"
            })
          document
            .querySelectorAll(".wpwl-control-cardHolder")
            .forEach((holderElement) => {
              holderElement.style["text-align"] = "center"
              holderElement.placeholder = "Max Mustermensch"
              holderElement.required = true
            })
          const customerEmailHtml =
            '<div class="wpwl-sup-wrapper wpwl-sup-wrapper-custom"><input type="text" name="customer.email" class="wpwl-control wpwl-control-custom" placeholder="E-Mail-Adresse" required></div>'
          const innerNode = document.createElement("div")
          innerNode.innerHTML = customerEmailHtml

          const customTextHtml =
            '<div class="wpwl-sup-wrapper wpwl-sup-wrapper-custom"><p>Für die Kreditkartensicherheit fordert die EU-Richtlinie PSD-2 eine starke Kundenauthentifizierung, deshalb sind alle Felder zwingend auszufüllen.</p></div>'
          const innerNodeTextHtml = document.createElement("div")
          innerNodeTextHtml.innerHTML = customTextHtml

          document
            .querySelectorAll("form.wpwl-form-card")
            .forEach((formCard) => {
              formCard
                .querySelectorAll(".wpwl-group-submit")
                .forEach(function callback (groupSubmit) {
                  formCard.insertBefore(innerNode.firstChild, groupSubmit)
                  formCard.insertBefore(
                    innerNodeTextHtml.firstChild,
                    groupSubmit
                  )
                })
            })
          // Change form field order and layout
          document.querySelectorAll("form.wpwl-form").forEach((formCard) => {
            const country = formCard.getElementsByClassName(
              "wpwl-sup-wrapper-country"
            )[0]
            const city = formCard.getElementsByClassName(
              "wpwl-sup-wrapper-city"
            )[0]
            const postcode = formCard.getElementsByClassName(
              "wpwl-sup-wrapper-postcode"
            )[0]
            const street1 = formCard.getElementsByClassName(
              "wpwl-sup-wrapper-street1"
            )[0]
            const street2 = formCard.getElementsByClassName(
              "wpwl-sup-wrapper-street2"
            )[0]
            if (country) {
              country.parentNode.insertBefore(street2, country)
              country.parentNode.insertBefore(street1, street2)
              country.parentNode.insertBefore(postcode, country)
              country.parentNode.insertBefore(city, country)
            }
            if (postcode) {
              postcode.style.display = "inline-block"
              postcode.style.width = "32%"
            }
            if (city) {
              city.style.display = "inline-block"
              city.style.width = "66%"
              city.style["margin-left"] = "2%"
            }
          })

          // Make required fields required
          // We do it with this strange method in order to not have red marks on initial load
          makeElementsRequired(true) // on blur
          document
            .querySelectorAll("form.wpwl-form button[type=submit]")
            .forEach(
              // when activating submit
              (element) => {
                element.addEventListener("click", () =>
                  makeElementsRequired(false)
                )
              }
            )

          // Prefill forms
          const baseElement = document.getElementById("payment-container")
          baseElement
            .querySelectorAll(
              'input[name="bankAccount.holder"], input[name="card.holder"]'
            )
            .forEach((elem) => {
              elem.value = personData?.name ?? ""
            })
          baseElement.querySelectorAll('input[name="billing.street1"]').forEach(
            // FIXME Multiple lines
            (elem) => {
              elem.value = personData?.address ?? ""
            }
          )
          baseElement
            .querySelectorAll('input[name="billing.city"]')
            .forEach((elem) => {
              elem.value = personData?.locality ?? ""
            })
          baseElement
            .querySelectorAll('input[name="billing.postcode"]')
            .forEach((elem) => {
              elem.value = personData?.postcode ?? ""
            })
          baseElement
            .querySelectorAll('input[name="billing.country"]')
            .forEach((elem) => {
              elem.value = personData?.country ?? ""
            })
          baseElement
            .querySelectorAll('input[name="customer.email"]')
            .forEach((elem) => {
              elem.value = personData?.email ?? ""
            })

          let googleRetry = 0

          function tryClickGpay () {
            const gpays = document.querySelectorAll(
              "form.wpwl-form button.wpwl-button-brand"
            )
            if (gpays.length) {
              // Triggers popup blocker
              gpays.forEach((button) => button.click())
            } else {
              if (googleRetry++ < 10) {
                window.setTimeout(tryClickGpay, 50)
              }
            }
          }

          if (["GOOGLEPAY", "PAYPAL"].includes(paymentBrand)) {
            window.setTimeout(tryClickGpay, 10)
          }
          outerThis.formReady = true
        },
        onBeforeSubmitCard: function (e) {
          return validateHolder()
        },
        onDetectBrand: function (brands) {
          document.querySelectorAll("form.wpwl-form").forEach((element) => {
            if (brands.length) {
              element.classList.add("brand-detected")
            } else {
              element.classList.remove("brand-detected")
            }
          })
        },
      }
      const paymentScript = document.createElement("script")
      paymentScript.src =
        config.payment.widgetBase + this.integrationData.checkoutId
      const paymentForm = document.createElement("form")
      paymentForm.action = this.integrationData.successUrl
      paymentForm.className = "paymentWidgets"
      paymentForm.setAttribute("data-brands", this.brand)
      Array.from(
        document.querySelectorAll("#payment-container form") ?? []
      ).forEach((node) => {
        node.remove()
      })
      document.getElementById("payment-container").appendChild(paymentForm)
      document.head.appendChild(paymentScript)
      this.formRendered = true
    },
    _intDestroyPaymentForm: function () {
      if (window?.wpwl?.unload) {
        try {
          window.wpwl.unload()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.exception("Exception in COPY+PAY: %O", e)
        }
        Array.from(document.getElementsByTagName("script")).forEach(function (
          node
        ) {
          if (node.src.indexOf("static.min.js") !== -1) {
            node.remove()
          }
        })
      }
      this.formRendered = false
      this.formReady = false
    },

    async startPaymentOnBackend () {
      const recurring = this.paymentInput.doRecurring
        ? {
          schedule: this.integrationData?.additionalData?.schedule,
          email: this.integrationData?.additionalData?.email,
        }
        : null
      const response = await axios.post(
        `${config.backend.rest}app/online_payment/start_payment/?app=${this.appKind}`,
        {
          collection_id: this.paymentInput.collection.id,
          collection: this.paymentInput.collection.id,
          amount: this.paymentInput.amount,
          ...this.selectedMethod,
          organization: this.paymentInput.parishId,
          person_data: this.$store.state.payment.personData,
          recurring,
        }
      )
      const data = response.data
      const checkoutId = data.checkout_id
      const path = getDonePath({
        organizationId: this.paymentInput.parishId,
        onlinePaymentId: data.id,
      }).href
      const successUrl = window.location.origin + path

      await Promise.allSettled([
        this.$store.commit("payment/" + UPDATE_INTEGRATION_DATA, {
          checkoutId,
          successUrl,
          onlinePaymentId: data.id,
        }),
        this.$store.commit("payment/" + SET_MANDATE_ID, {
          mandateId: data?.mandate_id || null,
        }),
        this.$store.commit("payment/" + SET_MEMO_TEXT, {
          memoText: data?.memo_text || null,
        }),
      ])
    },

    async completePaymentOnBackend () {
      try {
        const response = await axios.post(
          `${config.backend.rest}app/online_payment/update_payment/?app=${this.appKind}`,
          {
            online_payment: this.$store.state.route.params.onlinePaymentId,
            checkout_id: this.$store.state.route.query.id,
          }
        )
        const data = response.data
        await Promise.allSettled([
          this.$store.commit("payment/" + SET_MANDATE_ID, {
            mandateId: data?.mandate_id || null,
          }),
          this.$store.commit("payment/" + SET_MEMO_TEXT, {
            memoText: data?.memo_text || null,
          }),
        ])
        this.$emit("goto", "done", {
          successful: ["success", "pending", "hold"].includes(
            data.final_result_type
          ),
          data,
        })
      } catch (error) {
        this.$emit("goto", "done", {
          successful: false,
          message: `Technischer Fehler in der Datenübermittlung: ${error}`,
        })
      }
    },
  },
}
</script>

<style lang="stylus" scoped></style>

<style lang="stylus">
// Needs to be unscoped to apply to the magically generated oppwa form
// Hide brand selector, but show logo
.wpwl-label-brand, .wpwl-wrapper-brand, .wpwl-brand-card
  visibility: hidden

.brand-detected .wpwl-brand-card
  visibility: visible

// Style form
.wpwl-form-card
  border-radius: 0px
  background-image: unset
  background-color: rgba(255, 255, 255, 0.9)

.wpwl-sup-wrapper-custom-checkbox
  text-align: left
  margin-top: 8px

form.wpwl-form input[type="checkbox"]
  margin-right: 3px

.wpwl-button-pay
  background-color: var(--gebendigital-jscommon-integration-oppwa-primary-color)!important
  border-color: var(--gebendigital-jscommon-integration-oppwa-primary-color)!important
  box-shadow: 3px 7px 8px rgba(0, 0, 0, 0.15)
  margin-top: 20px
.wpwl-button-pay:hover
  background-color: var(--gebendigital-jscommon-integration-oppwa-primary-color)!important
  border-color: var(--gebendigital-jscommon-integration-oppwa-primary-color)!important
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15)
</style>
