export const SET_PAYMENT_STATE = "setPaymentState"
export const SET_PAYMENT_STATE_INITIAL = "setPaymentStateInitial"
export const SET_PAYMENT_RESULT = "setPaymentResult"
export const SET_INTEGRATION_DATA = "setIntegrationData"
export const UPDATE_INTEGRATION_DATA = "updateIntegrationData"
export const SET_PERSON_DATA = "setPersonData"
export const SET_DONATION_RECEIPT_CONFIGURATION =
  "setDonationReceiptConfiguration"
export const SET_MANDATE_ID = "setMandateId"
export const SET_MEMO_TEXT = "setMemoText"
