<template>
  <div class="modal-container">
    <v-text-field
      v-model="parishFilter"
      class="mt-8 mb-0"
      placeholder="Ort, Kirche, oder Gemeinde suchen"
      outlined
      filled
      :prepend-inner-icon="icon.mdiMagnify"
      :append-icon="
        locationAvailable
          ? coords
            ? icon.mdiCrosshairsGps
            : icon.mdiCrosshairs
          : null
      "
      hide-details="auto"
      @click:append="locateMe"
    />
    <div style="text-align: right" class="mt-1 mb-2">
      <a href="https://digitalespende.de/gemeinde-eintragen/" target="_blank"
        >Gemeinde fehlt?</a
      >
    </div>
    <div class="list-outline">
      <div class="list-box">
        <div>Wählen Sie Ihre Organisation:</div>
        <template v-for="kind in listCategories">
          <div v-if="chooserData[kind.level].length" :key="kind.level">
            <!-- <div>{{ kind.text }}</div> -->
            <router-link
              v-for="org in chooserData[kind.level]"
              :key="org.id"
              :to="{
                name: 'parish-selected',
                params: { parishId: org.id },
                query: $route.query,
              }"
              class="parishListItem"
              active-class="active"
            >
              <SmallLogo
                id="branding_logo-parish"
                :brand="brands[organizationSettingsByUrl[org.url].branding]"
                :is-default="
                  organizationSettingsByUrl[org.url].branding === null ||
                  organizationSettingsByUrl[org.url].branding === '_default'
                "
              />
              <div>
                {{ org.name | shortenParish }}
              </div>
              <!-- <div id="horizontal-line" /> -->
            </router-link>
          </div>
        </template>
        <div v-show="chooserData.isEmpty"><br />Kein Suchergebnis</div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/config"
import brands from "@/brands"
import { mapGetters, mapState } from "vuex"
import { getPosition } from "@/jscommon/src/lib/geo-tools"
import { mdiCrosshairs, mdiMagnify, mdiCrosshairsGps } from "@mdi/js"
import { SET_COORDS } from "@/store/mutation-types"

import SmallLogo from "@/components/icon/SmallLogo"

export default {
  name: "ParishForm",
  components: {
    SmallLogo,
  },
  data () {
    return {
      parishFilter: "",
    }
  },
  computed: {
    ...mapGetters("data", [
      "relevantOrganizationListOrdered",
      "organizationSettingsByUrl",
      "shortPathTree",
    ]),
    ...mapState("data", ["organizations", "coords"]),
    brands: function () {
      return brands
    },
    icon: function () {
      return {
        mdiCrosshairs,
        mdiMagnify,
        mdiCrosshairsGps,
      }
    },
    listCategories: function () {
      return [
        { level: "regions", text: "Wählen Sie Ihre Landeskirche: " },
        { level: "districts", text: "Wählen Sie Ihren Kirchenkreis: " },
        { level: "other", text: "Bitte Organisation auswählen: " },
        { level: "parishes", text: "Wählen Sie Ihre Gemeinde: " },
      ]
    },
    mode () {
      if (["home"].includes(this.$route.name)) {
        return "org-unit"
      } else if (["parish-selected", "done"].includes(this.$route.name)) {
        return "org"
      } else {
        return "search"
      }
    },
    currentNode () {
      if (!["org-unit", "search"].includes(this.mode)) {
        return null
      }
      let nodeName = null
      ;(this.$route.params?.orgPath ?? "").split("/").every((elem) => {
        const node = this.shortPathTree[nodeName]
        if (Object.prototype.hasOwnProperty.call(node.children, elem)) {
          nodeName = node.children[elem]
          return true
        } else {
          nodeName = null
          return false
        }
      })
      return this.shortPathTree[nodeName]
    },
    chooserData () {
      function filterListSorted (orgList, matchLevels, matchName) {
        const result = orgList
          .filter((org) => matchLevels.includes(org.level))
          .filter(
            (org) => org.name.toLowerCase().includes(matchName.toLowerCase()) // ||
            // this.options.filters.shortenParish(org.name).toLowerCase().includes(matchName.toLowerCase())
            // TODO: fix shortenParishes not being consideres for filter
          )
        result.sort((a, b) => {
          let distance = 0
          if (a?.distance && b?.distance) {
            distance = a.distance - b.distance
          }
          if (distance === 0) {
            return a.name.localeCompare(b.name)
          } else {
            return a - b
          }
        })
        return result
      }

      // FIXME Logic temporarily disabled
      let currentChildOrganizations = this.relevantOrganizationListOrdered
      if (0 && this.currentNode) {
        const currentChildren = Object.values(this.currentNode.children ?? {})
        currentChildOrganizations = currentChildOrganizations.filter((item) =>
          currentChildren.includes(item.url)
        )
      }

      const retval = {
        regions: filterListSorted(
          currentChildOrganizations,
          ["region"],
          this.parishFilter
        ),
        districts: filterListSorted(
          currentChildOrganizations,
          ["district"],
          this.parishFilter
        ),
        other: filterListSorted(
          currentChildOrganizations,
          ["other"],
          this.parishFilter
        ),
        parishes: filterListSorted(
          currentChildOrganizations,
          ["parish"],
          this.parishFilter
        ),
      }
      retval.isEmpty =
        !retval.regions.length &&
        !retval.districts.length &&
        !retval.other.length &&
        !retval.parishes.length
      return retval
    },
    locationAvailable () {
      return config.features.geoLocation && !!navigator.geolocation
    },
  },
  methods: {
    locateMe: async function () {
      const pos = await getPosition({})
      await this.$store.commit("data/" + SET_COORDS, pos.coords)
    },
  },
}
</script>
<style lang="stylus" scoped>
.modal-container
  text-align: left
  font-size: small
  color: rgba(0,0,0,0.54)

#parish-search-icon
  float: left
  bottom: 0.5em

#branding_logo-parish
  width: 2em
  padding-right: 0.4em
  vertical-align: middle

textarea:focus, #parish-search-input:focus
  outline: none

#parish-search-input-box
  margin-top: 1em
  padding: 1em
  width: calc(100% - 2em - 2px)
  height: 1.5em
  background: #DAD9D7
  border: 1px solid black
  margin-top: 3em

#parish-search-input
  position: relative
  width: 80%
  border: 0
  background: #DAD9D7
  font-size: small
  float: left

.parishListItem
  display: flex
  padding-left: 0.5em
  margin-top: 1px
  text-align: left
  vertical-align: middle
  line-height: normal
  border-bottom: 1px solid rgba(0, 0, 0, 0.2)
  min-height: 4em
  align-items:center
  justify-content:center

  div
    width: 100%
    padding-right: 0.4em

.parishListItem.active
  border: 2px solid var(--kollekteapp-primary-color)

  #horizontal-line
    background: rgba(0, 0, 0, 0.2)
    height: 0px

.close-modal-button
  float: right
  font-size: large
  font-weight: bold
  text-align: center
  width: 2em
  height: 2em
  line-height: 2em
  border-radius: 1em
  color: white
  margin-top: 0em
  margin-right: 0em
  color: black
  cursor: pointer

.close-modal-button:hover
  background: var(--kollekteapp-primary-color)
  color: white

.list-box
  max-height: 500px
  overflow-y: scroll
</style>
