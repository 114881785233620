export const getPaymentTypeHuman = (paymentType) => {
  switch (paymentType) {
    case "sepadd":
      return "SEPA-Lastschrift"
    case "giropay":
      return "Giropay"
    case "googlepay":
      return "Google Pay"
    case "paypal":
      return "PayPal"
    case "creditcard":
      return "Kreditkarte"
    default:
      return "Unbekannt"
  }
}
