import {
  SET_PAYMENT_STATE,
  SET_INTEGRATION_DATA,
  SET_PAYMENT_RESULT,
  SET_PAYMENT_STATE_INITIAL,
  SET_PERSON_DATA,
  SET_MANDATE_ID,
  SET_MEMO_TEXT,
  SET_DONATION_RECEIPT_CONFIGURATION,
  UPDATE_INTEGRATION_DATA,
} from "./mutation-types"
import { STATE_INIT } from "@/jscommon/src/store/payment/state-types"

export default {
  [SET_PERSON_DATA]: function (state, { personData }) {
    state.personData = personData
  },
  [SET_PAYMENT_STATE_INITIAL]: function (
    state,
    {
      organization,
      collection,
      amount,
      settings,
      paymentInformation,
      doRecurring,
    }
  ) {
    state.state = STATE_INIT

    state.input = {
      parishId: organization.id,
      collection,
      parishName: organization.name,
      merchantMemo: settings?.merchantMemo ?? null,
      paymentInformation,
      amount,
      privacyLink: settings?.privacyLink ?? null,
      features: settings?.features ?? null,
      doRecurring,
    }

    state.personData = null
    state.selectedIntegration = null
    state.integrationData = null

    state.result = {
      successful: false,
      message: null,
      data: null,
    }
  },
  [SET_PAYMENT_STATE]: function (state, value) {
    state.state = value
    if (value === null) {
      state.input = {
        parishId: null,
        collection: null,
        parishName: null,
        merchantMemo: null,
        paymentInformation: null,
        amount: 0,
        privacyLink: null,
        features: {},
        doRecurring: false,
      }
      state.personData = null
      state.selectedIntegration = null
      state.integrationData = null
    }
  },
  [SET_INTEGRATION_DATA]: function (
    state,
    { selectedIntegration, data } = { selectedIntegration: null, data: {} }
  ) {
    if (selectedIntegration) {
      state.selectedIntegration = selectedIntegration
    }
    state.integrationData = data
  },
  [UPDATE_INTEGRATION_DATA]: function (state, data = {}) {
    state.integrationData = { ...state.integrationData, ...data }
  },
  [SET_PAYMENT_RESULT]: function (state, data = {}) {
    state.result = { ...data }
  },
  [SET_MANDATE_ID] (state, { mandateId }) {
    state.mandateId = mandateId
  },
  [SET_MEMO_TEXT] (state, { memoText }) {
    state.memoText = memoText
  },
  [SET_DONATION_RECEIPT_CONFIGURATION] (
    state,
    { donationReceiptConfiguration }
  ) {
    state.donationReceiptConfiguration = donationReceiptConfiguration
  },
}
