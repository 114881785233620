export const SET_ORGANIZATIONS = "setOrganizations"
export const SET_COLLECTIONS_FOR_URL = "setCollectionsForUrl"
export const SET_PAYMENT_INFORMATION_FOR_URL = "setPaymentInformationForUrl"
export const SET_COORDS = "setCoords"
export const SET_EXTENSIONS_FOR_PLANS = "setExtensionsForPlans"

export const SET_PARISH_AND_COLLECTION = "setParishAndCollection"
export const UPDATE_TIME = "updateTime"
export const SET_BRANDING = "setBranding"
export const SET_IS_SINGLE_COLLECITON = "setSingleCollection"
export const SET_THEME = "setTheme"
