<template>
  <div>
    <h3 v-if="state && ![STATE_INIT, STATE_DONE].includes(state)">
      SEPA-Lastschrift
    </h3>
    <template v-if="state === STATE_INIT">
      <slot name="initiatingpre" />
      <v-text-field
        v-model="iban"
        outlined
        dense
        name="billing-name"
        autocomplete="account iban"
        label="Kontonummer (IBAN)"
        :rules="rules.iban"
        @blur="formatIban"
      />
      <slot name="initiatingpost" />
      <v-checkbox
        v-model="acceptSepaMandate"
        name="acceptSepaMandate"
        :rules="rules.acceptSepaMandate"
      >
        <template #label>
          * Ich erteile das SEPA-Lastschriftmandat.
          <template v-if="creditorId">
            Die Abbuchung erfolgt mit der Gläubiger-ID {{ creditorId }}.
          </template>
        </template>
      </v-checkbox>
    </template>
    <template v-if="state === 'handling'">
      <v-text-field
        v-model="name"
        outlined
        dense
        name="holder-name"
        autocomplete="billing name"
        label="Kontoinhaber"
        :rules="rules.name"
      />
      <v-text-field
        v-model="iban"
        outlined
        dense
        name="billing-name"
        autocomplete="account iban"
        label="Kontonummer (IBAN)"
        :rules="rules.iban"
        @blur="formatIban"
      />
      <v-checkbox
        v-model="acceptSepaMandate"
        name="acceptSepaMandate"
        :rules="rules.acceptSepaMandate"
      >
        <template #label>
          * Ich erteile das SEPA-Lastschriftmandat.
          <template v-if="creditorId">
            Die Abbuchung erfolgt mit der Gläubiger-ID {{ creditorId }}.
          </template>
        </template>
      </v-checkbox>
    </template>
    <slot name="actions" />
    <slot name="footer" :footerInfo="additionalFooterInformation" />
  </div>
</template>

<script>
import { checkIban, minLength, required } from "../lib/validator-tools"
import { friendlyFormatIBAN } from "ibantools"
import { mapState } from "vuex"
import axios from "axios"
import config from "@/config"
import { SET_MEMO_TEXT } from "../store/payment/mutation-types"
import {
  STATE_DONE,
  STATE_EXEC,
  STATE_HANDLE,
  STATE_INIT,
} from "../store/payment/state-types"

export default {
  name: "PaymentFragmentDirect",
  props: {
    state: {
      type: String,
      required: false,
      default: null,
    },
    appKind: {
      validator: (x) => ["spende.app", "kollekte.app"].includes(x),
      default: "kollekte.app",
    },
    selectedMethod: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      name: "",
      iban: null,
      acceptSepaMandate: false,
      acceptSepaMandateTime: null,
      STATE_INIT,
      STATE_DONE,
    }
  },
  computed: {
    ...mapState("payment", ["integrationData", "memoText"]),
    ...mapState("payment", {
      paymentInput: "input",
    }),
    rules () {
      return {
        name: [
          minLength(2, "Kontoinhaber muss angegeben werden"),
          required("Kontoinhaber muss angegeben werden"),
        ],
        iban: [
          required("Kontonummer muss angegeben werden"),
          checkIban("Gültige Kontonummer muss angegeben werden"),
        ],
        acceptSepaMandate: [required("Zustimmung erforderlich")],
      }
    },
    creditorId () {
      for (const pinf of this.$store.state.payment.input.paymentInformation) {
        for (const integration of pinf?.integrations ?? []) {
          if (
            integration?.integration === "direct" &&
            Object.prototype.hasOwnProperty.call(integration, "creditor_id")
          ) {
            return integration.creditor_id
          }
        }
      }
      return null
    },
    additionalFooterInformation () {
      if (this.memoText) {
        return `Dort erscheint „${this.memoText}“ als Verwendungszweck.`
      }

      const merchantMemo = this.paymentInput?.merchantMemo || ""

      if (merchantMemo) {
        return `Dort erscheint „${merchantMemo}“ als Verwendungszweck.`
      }

      return 'Dort erscheint das Wort „Spende" und der Name der Organisation als Verwendungszweck.'
    },
  },
  watch: {
    selectedMethod: {
      immediate: true,
      async handler (newValue) {
        if (newValue?.integration === "direct") {
          this.$emit("request-additional-fields", new Set(["name"]))
        }
      },
    },
    acceptSepaMandate: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.acceptSepaMandateTime = new Date().toISOString()
        }
      },
    },
    state: {
      immediate: true,
      async handler (newVal) {
        if (newVal === null) {
          this.reset()
        }

        if (this.selectedMethod.integration !== "direct") {
          return
        }

        if (newVal === STATE_HANDLE) {
          this.name = this?.integrationData?.additionalData?.name || ""
          this.$emit("goto", STATE_EXEC)
        } else if (newVal === STATE_EXEC) {
          await this.execute()
        }
      },
    },
  },
  methods: {
    formatIban () {
      this.iban = friendlyFormatIBAN(this.iban)
    },
    reset () {
      this.iban = null
      this.acceptSepaMandate = false
      this.acceptSepaMandateTime = null
    },
    async execute () {
      const state = this.$store.state.payment

      const localData = {
        name: this.name,
        iban: this.iban,
        accept_sepa_mandate_time: this.acceptSepaMandateTime,
      }

      const recurring = this.paymentInput.doRecurring
        ? {
          schedule: this.integrationData?.additionalData?.schedule,
          email: this.integrationData?.additionalData?.email,
        }
        : null

      try {
        const response = await axios.post(
          `${config.backend.rest}app/online_payment/start_payment/?app=${this.appKind}`,
          {
            collection_id: state.input.collection.id,
            collection: state.input.collection.id,
            amount: state.input.amount,
            ...this.selectedMethod,
            organization: state.input.parishId,
            person_data: state.personData,
            integration_data: localData,
            recurring,
          }
        )
        const data = response.data

        await this.$store.commit("payment/" + SET_MEMO_TEXT, {
          memoText: data?.memo_text || null,
        })

        this.$emit("goto", "done", {
          successful: ["success", "pending", "hold"].includes(
            data.final_result_type
          ),
          data,
        })
      } catch (error) {
        this.$emit("goto", "done", {
          successful: false,
          message: `Technischer Fehler in der Datenübermittlung: ${error}`,
        })
      }
    },
  },
}
</script>
