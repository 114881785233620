import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import en from "vuetify/lib/locale/en"
import de from "vuetify/lib/locale/de"

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en, de },
    current: "de",
  },
  icons: {
    iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  options: {
    customProperties: true,
  },
  theme: {
    themes: {
      light: {
        primary: "#554193",
        secondary: "#37AAE6",
        accent: "#554193",
        error: "#ae364b",
      },
    },
  },
})
