<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.15mm"
    height="6.21mm"
    viewBox="0 0 23.11 17.6"
  >
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_2-2" data-name="Ebene 2">
        <path
          class="cls-1"
          d="M21,8.14a24.87,24.87,0,0,0-2.39-2.51S21.31,2.89,21,1.86C21.13,3,16.81,3.74,11.45,4S1.66,4,1.55,2.8C1.46,3.85,4,5.89,4,5.89A7,7,0,0,0,1.55,8.7a8.52,8.52,0,0,0-1.1,3.92c0,3.07,2.3,4.54,11.11,4.53,8.43,0,11.1-1.46,11.1-4.53A9.82,9.82,0,0,0,21,8.14Z"
        />
        <path
          class="cls-1"
          d="M11.45,4c5.36-.23,9.68-1,9.57-2.11S16.43.28,11.07.51,1.45,1.64,1.55,2.8h0C1.66,4,6.09,4.19,11.45,4Z"
        />
        <path
          class="cls-2"
          d="M3.11,5.08s-.2-.15-.17.14h0c.06,1,3.75,1.66,8.35,1.6S20.37,5.75,19.64,5c-.26-.27-.07-.42-.07-.42"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "KollekteOutline",
}
</script>
<style>
.cls-1 {
  stroke: var(--kollekte-primary-color);
  stroke-width: 0.9px;
  fill: none;
  stroke-miterlimit: 10;
}

.cls-2 {
  stroke: var(--kollekte-primary-color);
  stroke-width: 0.79px;
  fill: none;
  stroke-miterlimit: 10;
}
</style>
