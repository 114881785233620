import moment from "moment"

export function ready (state, getters, rootState) {
  return state.parishId != null && !!rootState.data.organizations
}

export function currentCollection (state, getters) {
  const collections = getters.collections
  if (!getters.ready || collections.length === 0) {
    return {}
  }
  return collections[state.collectionIndex]
}

export function parishName (state, getters) {
  return getters.currentOrganization?.name ?? ""
}

export function currentOrganization (state, getters, rootState, rootGetters) {
  if (!getters.ready) {
    return {}
  }
  const organization =
    rootGetters["data/organizationLookupById"][state.parishId]
  if (!organization?.url) {
    return {}
  }
  return organization
}

export function collections (state, getters, rootState) {
  const organization = getters.currentOrganization
  if (!organization?.id) {
    return []
  }
  const extensions = rootState.data.extensions ?? []
  const collections = rootState.data.collectionsForUrl[organization.url] ?? []
  if (!collections.length) {
    return []
  }
  const result = []
  for (const x in Object.keys(collections)) {
    const collection = collections[x]
    const start = moment.utc(collection.plan.start)
    const end = moment.utc(collection.end)

    if (rootState.now < start || (end !== null && rootState.now > end)) {
      continue
    }
    collection.plan.extension = null
    collection.priority = defaultCollectionPriority(collection)
    for (const y in Object.keys(extensions)) {
      if (
        collection.plan?.id &&
        extensions[y]?.plan &&
        collection.plan.id === extensions[y].plan
      ) {
        collection.plan.extension = extensions[y]
        if (
          extensions[y].settings_local?.["kollekte.app"]?.display?.order
            ?.priority
        ) {
          collection.priority =
            extensions[y].settings_local?.[
              "kollekte.app"
            ].display.order.priority
        }
      }
    }
    result.push(collection)
  }
  result.sort(compareCollections)
  return result
}

/*
 * Order by collection type and variant
 */
function compareCollections (a, b) {
  // Possible values:
  // a.plan.collection_variant = inner / outer / x
  // a.plan.type = collection / donation
  // Desired order:
  // 1. collection / inner
  // 2. collection / outer
  // 3. collection / x
  // 4. donation

  // Neues Ziel:
  // 1 Hauptkollekte
  // 2 Nebenkollekte
  // 3 Freie Kollekte
  // 4. Spendenzwecke

  if (a.priority === b.priority) {
    // TODO: Order by creation date, last one created is displayed first.
    return 0 // remove
  } else if (a.priority < b.priority) {
    return -1
  } else {
    return 1
  }
  /* if (a.plan.type < b.plan.type) {
    return -1
  } else {
    if (a.plan.type === 'collection') {
      if (a.plan.collection_variant < b.plan.collection_variant) {
        return -1
      } else if (a.plan.collection_variant === b.plan.collection_variant) {
        if (a.plan.display.default > b.plan.display.default) {
          return 1
        }
        return -1
      } else {
        return 1
      }
    }
    return 1
  } */
}

function defaultCollectionPriority (collection) {
  if (collection === null) {
    return
  }
  if (collection.plan.type === "donation") {
    return 4
  } else {
    if (collection.plan.collection_variant === "outer") {
      return 2
    } else if (collection.plan.collection_variant === "x") {
      return 3
    } else if (collection.plan.collection_variant === "inner") {
      return 1
    } else {
      return 4
    }
  }
}
