var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-container"},[_c('v-text-field',{staticClass:"mt-8 mb-0",attrs:{"placeholder":"Ort, Kirche, oder Gemeinde suchen","outlined":"","filled":"","prepend-inner-icon":_vm.icon.mdiMagnify,"append-icon":_vm.locationAvailable
        ? _vm.coords
          ? _vm.icon.mdiCrosshairsGps
          : _vm.icon.mdiCrosshairs
        : null,"hide-details":"auto"},on:{"click:append":_vm.locateMe},model:{value:(_vm.parishFilter),callback:function ($$v) {_vm.parishFilter=$$v},expression:"parishFilter"}}),_vm._m(0),_c('div',{staticClass:"list-outline"},[_c('div',{staticClass:"list-box"},[_c('div',[_vm._v("Wählen Sie Ihre Organisation:")]),_vm._l((_vm.listCategories),function(kind){return [(_vm.chooserData[kind.level].length)?_c('div',{key:kind.level},_vm._l((_vm.chooserData[kind.level]),function(org){return _c('router-link',{key:org.id,staticClass:"parishListItem",attrs:{"to":{
              name: 'parish-selected',
              params: { parishId: org.id },
              query: _vm.$route.query,
            },"active-class":"active"}},[_c('SmallLogo',{attrs:{"id":"branding_logo-parish","brand":_vm.brands[_vm.organizationSettingsByUrl[org.url].branding],"is-default":_vm.organizationSettingsByUrl[org.url].branding === null ||
                _vm.organizationSettingsByUrl[org.url].branding === '_default'}}),_c('div',[_vm._v(" "+_vm._s(_vm._f("shortenParish")(org.name))+" ")])],1)}),1):_vm._e()]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.chooserData.isEmpty),expression:"chooserData.isEmpty"}]},[_c('br'),_vm._v("Kein Suchergebnis")])],2)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-1 mb-2",staticStyle:{"text-align":"right"}},[_c('a',{attrs:{"href":"https://digitalespende.de/gemeinde-eintragen/","target":"_blank"}},[_vm._v("Gemeinde fehlt?")])])}]

export { render, staticRenderFns }