import axios from "axios"
import config from "@/config"
import {
  SET_COLLECTIONS_FOR_URL,
  SET_PAYMENT_INFORMATION_FOR_URL,
  SET_ORGANIZATIONS,
  SET_EXTENSIONS_FOR_PLANS,
} from "@/store/mutation-types"
import { SET_DONATION_RECEIPT_CONFIGURATION } from "../../jscommon/src/store/payment/mutation-types"

/**
 *
 * @param { function } commit
 * @param { State } rootState
 */
export async function fetchOrganizations ({ commit, rootState }) {
  try {
    const response = await axios.get(
      config.backend.rest + "app/organization/",
      {
        params: {
          app: "kollekte.app",
        },
      }
    )
    const organizations = response.data
    await commit(SET_ORGANIZATIONS, { organizations })
  } catch (error) {
    // FIXME Implement retry with backoff. And/or error message
  }
}

export async function fetchCollectionInformationForUrl (
  { commit, state },
  { organizationUrl }
) {
  try {
    let collections = null
    let calendar = null
    let paymentInformation = null
    let extensions = null
    const response = await axios.get(
      `${organizationUrl}collection_information/`,
      {
        params: {
          app: "kollekte.app",
        },
      }
    )
    collections = []
    calendar = []
    for (const date of response.data.dates) {
      calendar[date.id] = date
    }
    for (const collection of response.data.collections) {
      if (collection.plan?.date !== null && collection.plan?.date in calendar) {
        collection.plan.date = calendar[collection.plan.date]
      }
      collections.push(collection)
    }
    paymentInformation = response.data.payment_information
    extensions = response.data.extensions

    const donationReceiptConfiguration =
      response.data?.donation_receipt_configuration

    // eslint-disable-next-line camelcase
    // const { collections, extensions, payment_information } = response.data
    await Promise.allSettled([
      await commit(SET_COLLECTIONS_FOR_URL, { organizationUrl, collections }),
      await commit(SET_EXTENSIONS_FOR_PLANS, { extensions }),
      await commit(SET_PAYMENT_INFORMATION_FOR_URL, {
        organizationUrl,
        paymentInformation: paymentInformation,
      }),
      await commit(
        "payment/" + SET_DONATION_RECEIPT_CONFIGURATION,
        { donationReceiptConfiguration },
        { root: true }
      ),
    ])
  } catch (error) {
    // FIXME retry etc.
  }
}
